import {
  BanknotesIcon,
  ChatBubbleBottomCenterIcon,
  HeartIcon,
  HomeIcon,
  InformationCircleIcon,
  MagnifyingGlassIcon,
  QuestionMarkCircleIcon,
  UserCircleIcon,
} from "@heroicons/react/24/outline";
import { AuthContext } from "contexts/AuthContext";
import React, { useContext, useEffect, useRef } from "react";
import { Link, useLocation } from "react-router-dom";
import { PathName } from "routers/types";
import MenuBar from "shared/MenuBar/MenuBar";
import isInViewport from "utils/isInViewport";

let WIN_PREV_POSITION = window.pageYOffset;

interface NavItem {
  name: string;
  link?: PathName;
  icon: any;
}

const NAV: NavItem[] = [
  {
    name: "Market",
    link: "/properties",
    icon: HomeIcon,
  },
  {
    name: "Borrow",
    link: "/borrow",
    icon: BanknotesIcon,
  },
  {
    name: "Log in",
    link: "/login",
    icon: UserCircleIcon,
  },
  {
    name: "Menu",
    icon: MenuBar,
  },
];

const NAV_PROD: NavItem[] = [
  {
    name: "Home",
    link: "/",
    icon: HomeIcon,
  },
  {
    name: "About",
    link: "/about",
    icon: InformationCircleIcon,
  },
  {
    name: "Contact",
    link: "/contact",
    icon: ChatBubbleBottomCenterIcon,
  },
  {
    name: "Menu",
    icon: MenuBar,
  },
];

const NAV_LOGGED: NavItem[] = [
  // {
  //   name: "Explore",
  //   link: "/",
  //   icon: MagnifyingGlassIcon,
  // },
  {
    name: "Market",
    link: "/properties",
    icon: HomeIcon,
  },
  {
    name: "Borrow",
    link: "/borrow",
    icon: BanknotesIcon,
  },
  {
    name: "Account",
    link: "/account",
    icon: UserCircleIcon,
  },
  {
    name: "Menu",
    icon: MenuBar,
  },
];

const FooterNav = () => {

  const containerRef = useRef<HTMLDivElement>(null);
  const { isLoggedIn } = useContext(AuthContext);
  const location = useLocation();

  // useEffect(() => {
  //   window.addEventListener("scroll", handleEvent);
  // }, []);

  // const handleEvent = () => {
  //   window.requestAnimationFrame(showHideHeaderMenu);
  // };

  // const showHideHeaderMenu = () => {
  //   let currentScrollPos = window.pageYOffset;
  //   if (!containerRef.current) return;

  //   // SHOW _ HIDE MAIN MENU
  //   if (currentScrollPos > WIN_PREV_POSITION) {
  //     if (
  //       isInViewport(containerRef.current) &&
  //       currentScrollPos - WIN_PREV_POSITION < 80
  //     ) {
  //       return;
  //     }

  //     containerRef.current.classList.add("FooterNav--hide");
  //   } else {
  //     if (
  //       !isInViewport(containerRef.current) &&
  //       WIN_PREV_POSITION - currentScrollPos < 80
  //     ) {
  //       return;
  //     }
  //     containerRef.current.classList.remove("FooterNav--hide");
  //   }

  //   WIN_PREV_POSITION = currentScrollPos;
  // };

  // if (!isLoggedIn) return <></>;

  return (
    <div
      ref={containerRef}
      className="FooterNav p-2 bg-white dark:bg-neutral-800 fixed top-auto bottom-0 inset-x-0 z-30 border-t border-neutral-300 dark:border-neutral-700 
      transition-transform duration-300 ease-in-out"
    >
      <div className="w-full max-w-lg flex justify-around mx-auto text-sm text-center ">
        {/* MENU */}
        {(isLoggedIn ? NAV_LOGGED : (process.env.REACT_APP_NODE_ENV == "development" ? NAV : NAV_PROD)).map((item, index) => {
          const active = location.pathname === item.link;
          return item.link ? (
            <Link
              key={index}
              to={item.link}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              <item.icon
                className={`w-6 h-6 ${active ? "text-primary-6000" : ""}`}
              />
              <span className={`text-[11px] leading-none mt-1 ${active ? "text-primary-6000" : ""}`}>{item.name}</span>
            </Link>
          ) : (
            <div
              key={index}
              className={`flex flex-col items-center justify-between text-neutral-500 dark:text-neutral-300/90 ${
                active ? "text-neutral-900 dark:text-neutral-100" : ""
              }`}
            >
              <item.icon iconClassName="w-6 h-6" className={``} />
              <span className="text-[11px] leading-none mt-1">{item.name}</span>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default FooterNav;
