import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import Badge from "shared/Badge/Badge";
import SectionVideos from "containers/PageHome/SectionVideos";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import videoArrowsImg from "images/videos_arrows.png";
import web3SimplerBgLeftImg from "images/web3simpler_bg_left.png";
import web3SimplerBgRightImg from "images/web3simpler_bg_right.png";

export interface SectionWeb3SimplerProps {
  className?: string;
}

const SectionWeb3Simpler: FC<SectionWeb3SimplerProps> = ({
  className = "",
}) => {

  const { t } = useTranslation();

  return (
    <div className={`${/*!mt-44 md:!mt-96*/void(0)} nc-SectionWeb3Simpler -ml-4 -mr-4 md:-ml-24 md:-mr-24 bg-[#348282] pt-16 pb-28 p-4 pb-16 md:p-24 relative`}> 

      <div className="relative z-10 max-w-6xl mx-auto ">
        <ScrollAnimation animateIn="slideInLeft" className="flex flex-col mb-10 md:mb-0">
          <img src={videoArrowsImg} className="w-16" />
          <h2 className="font-semibold text-white text-3xl md:text-3xl mt-5">🎬&nbsp; {t('home.videos.title')}</h2>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn">
          <SectionVideos />
        </ScrollAnimation>
      </div>

      <img src={web3SimplerBgLeftImg} className="absolute left-0 bottom-0 w-[75%] md:w-[25%]" />
      <img src={web3SimplerBgRightImg} className="absolute right-0 top-0 w-[75%] md:w-[25%]" />
    </div>
  );
};

export default SectionWeb3Simpler;
