import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import Hover from "react-3d-hover";
import polygonSectionImg from "images/polygon_section.png";
import callToActionImg from "images/call_to_action_bg.png";
import poweredByPolygonImg from "images/powered_by_polygon.png";

export interface SectionChainProps {
  className?: string;
}

const SectionChain: FC<SectionChainProps> = ({
  className = "",
}) => {

  const { t } = useTranslation();

  return (
    <div className={`nc-SectionChain -ml-4 -mr-4 md:-ml-24 md:-mr-24 relative bg-primary-50 bg-opacity-40`}> 
      <div className="grid grid-cols-1 md:grid-cols-2 relative z-10 bg-[#1C1C1C]">
        
        <ScrollAnimation animateIn="slideInLeft" className="w-full h-full flex items-center justify-center py-16 md:py-24">
          <Hover scale={1.025} speed={500} max={30} perspective={700}>
            <img src={polygonSectionImg} className="w-[65%] md:w-[55%] m-auto" />
          </Hover>
        </ScrollAnimation>
        
        <div className="w-full h-full bg-[#262626] px-4 py-24 md:p-16 md:py-32 space-y-8 flex flex-col justify-center">

          <img src={callToActionImg} className="hidden md:inline-block absolute z-0 top-0 -right-[25%] object-cover h-full opacity-70" style={{ transform: "rotateY(180deg)" }} />

          <h2 className="text-white font-bold text-3xl text-left md:text-left !-mt-4">{t('home.chain.title')}</h2>
          <p className="text-neutral-50 text-left text-base md:text-left">
            {t('home.chain.desc')}
            <br/>
            <br/>
            {t('home.chain.desc2')}
          </p>
          <img src={poweredByPolygonImg} className="w-44" />
        </div>
      </div>
    </div>
  );
};

export default SectionChain;
