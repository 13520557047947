import { useSnackbar } from "notistack";
import React, { ReactNode, useRef, useState } from "react";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ForgotPassword1 from "./ForgotPassword1";
import ForgotPassword2 from "./ForgotPassword2";

export interface PageForgotPasswordProps {
}

const PageForgotPassword: FC<PageForgotPasswordProps> = () => {

  const [index, setIndex] = useState<number>(0); 
  const maxStep = 1;

  const [form, setForm] = useState<any>();
  const formRef = useRef<HTMLFormElement>(null);

  const [disableContinue, setDisableContinue] = useState<boolean>(true);
  const [loadingContinue, setLoadingContinue] = useState<boolean>(false);
  const [isGoBack, setIsGoBack] = useState<boolean>(true);
  const [isContinue, setIsConitnue] = useState<boolean>(true);
  const [done, setDone] = useState<boolean>(false);

  const history = useHistory();

  const components: React.ReactElement[] = [
    <ForgotPassword1 form={form} setDisableContinue={setDisableContinue} setLoadingContinue={setLoadingContinue} setIsGoBack={setIsGoBack} />,
    <ForgotPassword2 form={form} setDisableContinue={setDisableContinue} setLoadingContinue={setLoadingContinue} setIsGoBack={setIsGoBack} setIsContinue={setIsConitnue} />
  ];

  const keyPress = (e: any): void => {
    if (e.key == "Enter") nextStep();
  }

  const prevStep = () => {
    index == 0 ? history.push("/login") : setIndex(index-1);
  }

  const nextStep = () => {
    const data = new FormData(formRef.current as HTMLFormElement);
    const formObject = Object.fromEntries(data.entries());
    const newForm = {...form, ...formObject};
    setForm(newForm);
    if (index < maxStep) {
      setIndex(index+1);
      setIsGoBack(true);
      if (index+1 == maxStep) {
        fetch(`${process.env.REACT_APP_API_URL}/users/forgot_password`, {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          credentials: "include",
          body: JSON.stringify(newForm),
        }).then((response) => {
          return response.json();
        }).then((json) => {
          
        });
      }
    }
  }

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-24 pt-14 sm:py-24 lg:pb-32 h-[100vh] md:h-auto`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11 relative h-full">
        <>
          <form ref={formRef} onKeyPress={keyPress} className="listingSection__wrap md:pb-16 w-full display-block h-full border-b-0 md:border-b">
            {components[index]}
          </form>
          
          <div className="flex justify-end space-x-5 fixed left-0 bottom-0 md:relative p-5 w-full md:p-0">
            {isGoBack && (
              <ButtonSecondary className="w-full md:w-auto" onClick={() => { prevStep() }}>Go back</ButtonSecondary>
            )}
            {isContinue && (
              <ButtonPrimary disabled={disableContinue} loading={loadingContinue} className="w-full md:w-auto" onClick={() => { nextStep() }}>{index == maxStep ? "Create Account" : "Continue"}</ButtonPrimary>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default PageForgotPassword;
