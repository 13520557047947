import React, { FC, useContext, useRef, useState } from "react";
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";
import { Helmet } from "react-helmet";
import Input from "shared/Input/Input";
import { Link } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { useGoogleLogin } from '@react-oauth/google';
import { TwitterLogin } from "react-twitter-login";
import FacebookLogin from 'react-facebook-login';
import Logo from "shared/Logo/Logo";
import { AuthContext } from "contexts/AuthContext";
import houseInHandImg from "images/house-in-hand.jpg";

export interface PageLoginProps {
  className?: string;
}

const PageLogin: FC<PageLoginProps> = ({ className = "" }) => {

  const [email, setEmail] = useState<String>("");
  const [password, setPassword] = useState<String>("");
  const [isLoggingIn, setIsLoggingIn] = useState<boolean>(false);
  const { login } = useContext(AuthContext);

  const onFailure = (e:any) => {
    console.log(e);
  };

  const passwordLogin = () => {
    setIsLoggingIn(true);
    const tokenBlob = new Blob([JSON.stringify({email: email, password: password}, null, 2)], {type : 'application/json'});
    fetch(`${process.env.REACT_APP_API_URL}/auth/password`, {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default',
      credentials: 'include'
    }).then(r => {
      const token = r.headers.get('x-auth-token');
      r.json().then(user => {
        if (token) {
          login(token, user);
        }
      });
    }).finally(() => {
      setIsLoggingIn(false);
    });
  }

  const facebookResponse = (e:any) => {
    const tokenBlob = new Blob([JSON.stringify({access_token: e.accessToken}, null, 2)], {type : 'application/json'});
    fetch(`${process.env.REACT_APP_API_URL}/auth/facebook`, {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default',
      credentials: 'include'
    }).then(r => {
      const token = r.headers.get('x-auth-token');
      r.json().then(user => {
        if (token) {
          login(token, user);
        }
      });
    })
  };
  
  const googleResponse = (e:any) => {
    console.log(e);
    const tokenBlob = new Blob([JSON.stringify({access_token: e.access_token}, null, 2)], {type : 'application/json'});
    fetch(`${process.env.REACT_APP_API_URL}/auth/google`, {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default',
      credentials: 'include'
    }).then(r => {
      const token = r.headers.get('x-auth-token');
      r.json().then(user => {
        if (token) {
          login(token, user);
        }
      });
    })
  };

  const googleLogin = useGoogleLogin({ 
    onSuccess: googleResponse, 
    onError: onFailure,
  });

  return (
    <div className={`nc-PageLogin ${className}`} data-nc-id="PageLogin">
      <Helmet>
        <title>Login || Reblox Real Estate</title>
      </Helmet>
      <div className="flex">
        <div className="w-0 md:w-1/2">
          <img src={houseInHandImg} className="object-cover w-full h-full min-h-[calc(100vh-88px)]" />
        </div>
        <div className="md:w-1/2 container flex items-center justify-center py-10">
          <div className="w-full">
            <h2 className="mb-10 md:mb-16 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
              <Logo className="w-40 md:w-52" />
            </h2>
            <div className="max-w-md mx-auto space-y-6">
              <div className="grid gap-3">

                <div onClick={() => googleLogin()} className="cursor-pointer nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]">
                  <img className="flex-shrink-0" src={googleSvg} />
                  <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                    Continue with Google
                  </h3>
                </div>

                <FacebookLogin 
                  appId={process.env.REACT_APP_FACEBOOK_APP_ID as string} 
                  autoLoad={false} 
                  fields="name,email,picture" 
                  callback={facebookResponse} 
                  buttonStyle={{ fontFamily: "Poppins", fontSize: "0.875rem", fontWeight: "500" }}
                  textButton=""
                  icon={<>
                    <img className="flex-shrink-0" src={facebookSvg} />
                    <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                      Continue with Facebook
                    </h3>
                  </>}
                  cssClass={"cursor-pointer nc-will-change-transform flex w-full items-center rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"} 
                />
                
                {/* <TwitterLogin
                  authCallback={twitterResponse}
                  consumerKey={process.env.REACT_APP_TWITTER_API_KEY as string}
                  consumerSecret={process.env.REACT_APP_TWITTER_API_KEY_SECRET as string}
                >
                  <div className="cursor-pointer nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]">
                    <img className="flex-shrink-0" src={twitterSvg} />
                    <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                      Continue with Twitter
                    </h3>
                  </div>
                </TwitterLogin> */}
              </div>
              {/* OR */}
              <div className="relative text-center">
                <span className="relative z-10 inline-block px-4 font-medium text-sm bg-white dark:text-neutral-400 dark:bg-neutral-900">
                  OR
                </span>
                <div className="absolute left-0 w-full top-1/2 transform -translate-y-1/2 border border-neutral-100 dark:border-neutral-800"></div>
              </div>
              {/* FORM */}
              <div className="grid grid-cols-1 gap-6">
                <label className="block">
                  <span className="text-neutral-800 dark:text-neutral-200">
                    Email address
                  </span>
                  <Input
                    type="email"
                    placeholder="example@example.com"
                    className="mt-1"
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </label>
                <label className="block">
                  <span className="flex justify-between items-center text-neutral-800 dark:text-neutral-200">
                    Password
                    <Link to="/forgot-password" className="text-sm">
                      Forgot password?
                    </Link>
                  </span>
                  <Input 
                    type="password" 
                    className="mt-1" 
                    onChange={(e) => setPassword(e.target.value)}
                  />
                </label>
                <ButtonPrimary loading={isLoggingIn} onClick={() => passwordLogin()}>Continue</ButtonPrimary>
              </div>

              {/* ==== */}
              <span className="block text-center text-neutral-700 dark:text-neutral-300">
                New user? {` `}
                <Link to="/signup" className="text-primary-500">Create an account</Link>
              </span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PageLogin;
