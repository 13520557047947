import React from "react";
import { useTranslation } from "react-i18next";
import ncNanoId from "utils/ncNanoId";
import NavigationItem, { NavItemType } from "./NavigationItem";

function Navigation() {
  const { t } = useTranslation();

  const navigation: NavItemType[] = [
    {
      id: ncNanoId(),
      href: "/",
      name: t('navbar.home'),
      isNew: false,
    },
    {
      id: ncNanoId(),
      href: "/about",
      name: t('navbar.about'),
      isNew: false,
    },
    {
      id: ncNanoId(),
      href: "/properties",
      name: t('navbar.properties'),
      isNew: true,
    },
    // {
    //   id: ncNanoId(),
    //   href: "/borrow",
    //   name: t('navbar.borrow'),
    //   isSoon: true,
    // },
    {
      id: ncNanoId(),
      href: "/contact",
      name: t('navbar.contact'),
      isNew: false,
    },
  ];
  
  const navigationProd: NavItemType[] = [
    {
      id: ncNanoId(),
      href: "/",
      name: t('navbar.home'),
      isNew: false,
    },
    {
      id: ncNanoId(),
      href: "/about",
      name: t('navbar.about'),
      isNew: false,
    },
    {
      id: ncNanoId(),
      href: undefined,
      name: t('navbar.properties'),
      isSoon: true,
    },
    {
      id: ncNanoId(),
      href: "/contact",
      name: t('navbar.contact'),
      isNew: false,
    },
    // {
    //   id: ncNanoId(),
    //   href: undefined,
    //   name: t('navbar.borrow'),
    //   isSoon: true,
    // },
  ];

  return (
    <ul className="nc-Navigation hidden lg:flex lg:flex-wrap lg:items-center lg:space-x-2.5 relative">
      {(process.env.REACT_APP_NODE_ENV == "development" ? navigation : navigationProd).map((item) => (
        <NavigationItem key={item.id} menuItem={item} />
      ))}
    </ul>
  );
}

export default Navigation;
