import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Logo from "shared/Logo/Logo";
import simulateInvestmentBgImg from "images/simulate_investment_bg.png";
import featuresBgImg from "images/features_bg.png";
import ScrollAnimation from "react-animate-on-scroll";
import BgRebloxAbout from "components/BgRebloxAbout/BgRebloxAbout";

export interface SectionHeroProps {
  className?: string;
  rightImg: string;
  heading: ReactNode;
  subHeading: ReactNode;
  btnText: string;
}

const SectionHero: FC<SectionHeroProps> = ({
  className = "",
  rightImg,
  heading,
  subHeading,
  btnText,
}) => {
  return (
    <div
      className={`nc-SectionHero relative ${className}`}
      data-nc-id="SectionHero"
    >
      {/* <img src={featuresBgImg} className="hidden md:inline-block absolute -left-24 w-[30%] z-0 -top-10" /> */}

      <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center justify-center relative text-center lg:text-left">
        <div className="w-screen max-w-full xl:max-w-2xl space-y-5 lg:space-y-7">
          <h2 className="text-3xl text-center !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
            {heading}
          </h2>
          <span className="block text-center text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
            {subHeading}
          </span>
          {!!btnText && <ButtonPrimary href="/login">{btnText}</ButtonPrimary>}
        </div>
        {/* <div className="w-1/2">
          <img className="w-full" src={rightImg} alt="" />
        </div> */}
      </div>
    </div>
  );
};

export default SectionHero;
