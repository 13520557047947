import React, { FC, useContext, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./PageCreateAccount";
import FormItem from "./FormItem";
import { useSnackbar } from "notistack";
import { AuthContext } from "contexts/AuthContext";
import FacebookLogin from 'react-facebook-login';
import { useGoogleLogin } from '@react-oauth/google';
import facebookSvg from "images/Facebook.svg";
import googleSvg from "images/Google.svg";

export interface CreateAccount1Props {
  form: any;
  setDisableContinue(disabled: boolean): void;
  setLoadingContinue(disabled: boolean): void;
  setIsGoBack(disabled: boolean): void;
}

const CreateAccount1: FC<CreateAccount1Props> = ({form, setDisableContinue, setLoadingContinue, setIsGoBack}) => {

  const { isLoggedIn } = useContext(AuthContext);
  const [email, setEmail] = useState<any>(form?.email || "");
  const [password, setPassword] = useState<any>(form?.password || "");
  const [errors, setErrors] = useState<any>({});
  const [emailAlreadyRegistered, setEmailAlreadyRegistered] = useState<boolean>(true);
  const { enqueueSnackbar } = useSnackbar();
  const { login } = useContext(AuthContext);

  const checkUppercase = (str: String) => {
    for (var i=0; i<str.length; i++){
      if (str.charAt(i) == str.charAt(i).toUpperCase() && str.charAt(i).match(/[a-z]/i)){
        return true;
      }
    }
    return false;
  };

  const hasNumbers = (myString: String) => {
    return /\d/.test(myString as string);
  }

  const validateEmail = (email: String) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onFailure = (e:any) => {
    console.log(e);
  };

  const facebookResponse = (e:any) => {
    const tokenBlob = new Blob([JSON.stringify({access_token: e.accessToken}, null, 2)], {type : 'application/json'});
    fetch(`${process.env.REACT_APP_API_URL}/auth/facebook`, {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default'
    }).then(r => {
      const token = r.headers.get('x-auth-token');
      r.json().then(user => {
        if (token) {
          login(token, user);
          window.location.href="/account";
        }
      });
    })
  };
  
  const googleResponse = (e:any) => {
    console.log(e);
    const tokenBlob = new Blob([JSON.stringify({access_token: e.access_token}, null, 2)], {type : 'application/json'});
    fetch(`${process.env.REACT_APP_API_URL}/auth/google`, {
      method: 'POST',
      body: tokenBlob,
      mode: 'cors',
      cache: 'default'
    }).then(r => {
      const token = r.headers.get('x-auth-token');
      r.json().then(user => {
        if (token) {
          login(token, user);
          window.location.href="/account";
        }
      });
    })
  };

  const googleLogin = useGoogleLogin({ 
    onSuccess: googleResponse, 
    onError: onFailure,
  });

  useEffect(() => {
    if (validateEmail(email) && !isLoggedIn) {
      setEmailAlreadyRegistered(false);
      fetch(`${process.env.REACT_APP_API_URL}/users/check_email_exists`, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          email: email,
        }),
      }).then((response) => {
        return response.json();
      }).then((json) => {
        if (json.success == true) {
          setErrors({...errors, email: ""});
          setEmailAlreadyRegistered(false);
        } else {
          setEmailAlreadyRegistered(true);
          enqueueSnackbar("Email already registered. Log in instead.", { variant: "error" });
        } 
      });
    }
  }, [email]);

  useEffect(() => {
    let newErrors = {};
    let error = false;
    if (!validateEmail(email)) {
      error = true;
      if (email.length > 0) {
        newErrors = {...newErrors, email: "You need to enter a valid email."};
      }
    }
    if (password.length < 8 || !checkUppercase(password) || !hasNumbers(password)) {
      error = true;
      if (password.length > 0) {
        newErrors = {...newErrors, password: "The password should match the required format."};
      }
    } else {
      newErrors = {...newErrors, password: ""};
    }
    if (emailAlreadyRegistered && validateEmail(email) && !isLoggedIn) {
      newErrors = {...newErrors, email: "Email already registered. Log in instead."};
      error = true;
    }
    setErrors({...errors, ...newErrors});
    setDisableContinue(error);
  }, [email, password, emailAlreadyRegistered]);

  return (
    <>
      <div className="space-y-1">
        <h2 className="text-2xl font-semibold">Get started</h2>
        <p className="text-sm">Create an account with Google or Facebook, or type your email and password.</p>
      </div>
      {/* <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div> */}
      
      <div className="flex flex-col md:flex-row md:space-x-3 space-y-3 md:space-y-0">
        <div onClick={() => googleLogin()} className="cursor-pointer nc-will-change-transform flex w-full rounded-lg bg-primary-50 dark:bg-neutral-800 px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]">
          <img className="flex-shrink-0" src={googleSvg} />
          <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
            Continue with Google
          </h3>
        </div>
        <div className="w-full">
          <FacebookLogin 
            appId={process.env.REACT_APP_FACEBOOK_APP_ID as string} 
            autoLoad={false} 
            fields="name,email,picture" 
            callback={facebookResponse} 
            buttonStyle={{ fontFamily: "Poppins", fontSize: "0.875rem", fontWeight: "500" }}
            textButton=""
            icon={<>
              <img className="flex-shrink-0" src={facebookSvg} />
              <h3 className="flex-grow text-center text-sm font-medium text-neutral-700 dark:text-neutral-300 sm:text-sm">
                Continue with Facebook
              </h3>
            </>}
            cssClass={"cursor-pointer nc-will-change-transform flex w-full items-center rounded-lg bg-primary-50 dark:bg-neutral-800 !w-full block px-4 py-3 transform transition-transform sm:px-6 hover:translate-y-[-2px]"} 
          />
        </div>
      </div>
      
      <div className="flex items-center justify-center space-x-3 -mt-2 -mb-2">
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <span>or</span>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      </div>

      <div className="space-y-8">
        <FormItem
          label="Email"
          desc="You will use your email as your username to login to Reblox Real Estate."
          error={errors?.email}
        >
          <Input name="email" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="your@email.com" value={email} />
        </FormItem>
        <FormItem
          label="Password"
          desc="The password must be at least 8 characters long and must contain a number and an uppercase letter."
          error={errors?.password}
        >
          <Input name="password" type="password" onChange={(e) => setPassword(e.target.value)} placeholder="********" value={password} />
        </FormItem>
      </div>
    </>
  );
};

export default CreateAccount1;
