import { PlusIcon } from "@heroicons/react/24/outline";
import { useEthers } from "@usedapp/core";
import { ConnectWalletButton } from "components/ConnectWalletButton";
import Label from "components/Label/Label";
import { AuthContext } from "contexts/AuthContext";
import { useSnackbar } from "notistack";
import React, { useContext, useEffect, useState } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import NcModal from "shared/NcModal/NcModal";
import Wallet from "./Wallet";

const Wallets = () => {

  const { user, token, setUser } = useContext(AuthContext);
  const { account, deactivate } = useEthers();
  const [ addExternalWalletModalOpen, setAddExternalWalletModalOpen ] = useState<boolean>(false);
  const [ confirmAddExternalWalletModalOpen, setConfirmAddExternalWalletModalOpen ] = useState<boolean>(false);
  const [ provider, setProvider ] = useState<String>();
  const [ isAddingWallet, setIsAddingWallet ] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (account && addExternalWalletModalOpen) {
      setAddExternalWalletModalOpen(false);
      setConfirmAddExternalWalletModalOpen(true);
    }
  }, [account]);

  const addWallet = () => {
    setIsAddingWallet(true);
    fetch(`${process.env.REACT_APP_API_URL}/users/add_wallet`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        userId: user?.id,
        token: token,
        address: account,
        provider: provider
      })
    }).then((response) => {
      return response.json();
    }).then((json) => {
      setUser(json.data);
      enqueueSnackbar("Wallet added succesfully", { variant: "success" });
      // setPrivateKey(json.data);
    }).finally(() => {
      setIsAddingWallet(false);
      setConfirmAddExternalWalletModalOpen(false);
    });
  }

  return (
    <div>
      <div className="space-y-6 sm:space-y-8 w-full">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl md:text-3xl font-semibold">Your Wallets</h2>
          <p className="text-neutral-400 dark:text-neutral-500">You can add external wallets or use your already created Reblox Wallet.</p>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="space-y-6">
          {user.wallets.map((item:any, index:number) => (
            <Wallet key={index} data={item} />
          ))}
          <div className="text-center md:text-right">
            <NcModal
              renderTrigger={(openModal) => (<ButtonPrimary onClick={() => setAddExternalWalletModalOpen(true)}><PlusIcon className="w-5 h-5 mr-2.5" /> Add new external wallet</ButtonPrimary>)}
              renderContent={() => (
                <div>
                  <p className="text-center">
                    You can add any external wallet (Metamask, Coinbase, TrustWallet, WalletConnect, etc) by 
                    connecting it to Reblox site.
                    <br />
                    <br />
                    Press the "Connect" button to continue adding your external wallet.
                  </p>
                </div>
              )}
              renderFooter={() => (
                <div className="flex justify-end space-x-2">
                  <ButtonSecondary onClick={() => { deactivate(); setAddExternalWalletModalOpen(false) }}>Close</ButtonSecondary>
                  <ConnectWalletButton autoDeactivate={true} setProvider={setProvider} />
                </div>
              )}
              isOpenProp={addExternalWalletModalOpen}
              modalTitle="Add External Wallet"
            />
            
            <NcModal
              renderTrigger={() => <></>}
              renderContent={() => (
                <div>
                  <p className="text-center">
                    <img src={`/images/wallets/${provider}.png`} className="w-24 inline" />
                    <br />
                    <br />
                    Are you sure you want to add the external wallet with address: 
                    <br/>
                    <br/>
                    <b className="break-words">{account}</b>
                    <br />
                    <br />
                    Press the "Confirm" button to add the external wallet.
                  </p>
                </div>
              )}
              renderFooter={() => (
                <div className="flex justify-end space-x-2">
                  <ButtonSecondary onClick={() => setConfirmAddExternalWalletModalOpen(false)}>Close</ButtonSecondary>
                  <ButtonPrimary loading={isAddingWallet} onClick={() => addWallet()}>Confirm</ButtonPrimary>
                </div>
              )}
              isOpenProp={confirmAddExternalWalletModalOpen}
              modalTitle="Add External Wallet"
            />
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallets;
