import React, { FC } from "react";
import isologo from "images/isologo.png";

export interface BgRebloxProps {
  className?: string;
}

const BgReblox: FC<BgRebloxProps> = ({
  className = "absolute inset-x-0 min-h-0 flex overflow-hidden z-0 w-full justify-between",
}) => {
  return (
    <div
      className={`nc-BgReblox ${className}`}
      data-nc-id="BgReblox"
    >
      <div className="w-full hidden md:inline-block ">
        <img src={isologo} className="opacity-10 mt-12 w-[70%]" />
      </div>
      <div className="w-full text-right">
        <img src={isologo} className="opacity-10 ml-[50%] mt-4 w-[80%]" />
      </div>
    </div>
  );
};

export default BgReblox;
