import Logo from "shared/Logo/Logo";
import SocialsList1 from "shared/SocialsList1/SocialsList1";
import { CustomLink } from "data/types";
import React, { useContext } from "react";
import { AuthContext } from "contexts/AuthContext";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useTranslation } from "react-i18next";
import footerArrowsImg from "images/footer_arrows.png";
import footerFilesImg from "images/footer_files.png";
import { Link } from "react-router-dom";

const Footer: React.FC = () => {
  
  const { t } = useTranslation();

  return (
    <div style={{ background: "#262626" }} className="nc-Footer relative border-t border-[#ffffff22]">

      <div className="relative container grid grid-cols-1 gap-16 sm:gap-16 md:grid-cols-3 lg:grid-cols-3 lg:gap-24 pb-32 py-14 lg:py-28 z-10">

        <div className="hidden md:flex flex-col space-y-6 items-center md:items-start md:pr-16">
          <div className="flex items-center space-x-4">
            <div>
              <img src={footerArrowsImg} className="w-14" />
            </div>
            <div className="text-primary-400 text-2xl md:text-2xl font-bold text-center md:text-left">
              {t('footer.ready_to_invest')}
            </div>
            <div className="md:hidden">
              <img src={footerArrowsImg} className="w-14" style={{ transform: "rotateY(180deg)" }} />
            </div>
          </div>
          <hr className="w-full" />
          <ButtonSecondary className="w-fit px-10">{t('footer.ready_to_invest_button')}</ButtonSecondary>
        </div>

        <div className="flex flex-col justify-center space-y-10 md:-mt-2">
          <div className="text-center">
            <Logo className="w-44 md:w-56" isLight={true} />
          </div>
          <div className="flex items-center">
            <SocialsList1 className="grid grid-cols-2 md:grid-cols-3 text-white gap-4 w-full" />
          </div>
        </div>

        <div className="flex md:flex-col items-center md:items-end md:space-y-10 space-x-8 md:space-x-0 justify-center md:justify-start">
          <Link to="/about"><h1 className="text-white text-lg md:text-2xl font-bold flex items-center space-x-3"><span>{t('footer.about')}</span></h1></Link>
          <Link to="/contact"><h1 className="text-white text-lg md:text-2xl font-bold flex items-center space-x-3"><span>{t('footer.contact')}</span></h1></Link>
          <a href="https://reblox.gitbook.io/reblox/" target="_blank">
            <h1 className="text-white text-lg md:text-2xl font-bold flex items-center space-x-3"><span>{t('footer.docs')}</span> 
              <img src={footerFilesImg} className="h-5"/>
            </h1>
          </a>
        </div>

      </div>

    </div>
  );
};

export default Footer;
