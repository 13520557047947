import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import detectBrowserLanguage from "detect-browser-language";

const resources = {
  en: {
    translation: {
      navbar: {
        home: "Home",
        about: "About us",
        properties: "Marketplace",
        borrow: "Borrow",
        contact: "Contact us",
        login: "Log in",
        signup: "Sign up"
      },
      home: {
        hero: {
          title: "Building the future of Real Estate",
          subtitle: "Crowdfunding real estate investing using tokenization and blockchain",
          button: "Sign up"
        },
        subhero: {
          title: "Welcome to ",
          title2: "Real Estate 3.0",
          subtitle: "OWN YOUR PROPERTY",
        },
        proposal: {
          title: "Reblox Proposal",
          subtitle: "BENEFITS",
          item0: {
            badge: "User first",
            title: "Secure and Simple",
            desc: "Invest with no minimums and boost your savings with the safety of real estate that our company has acquired."
          },
          item1: {
            badge: "Innovation",
            title: "Blockchain Tech",
            desc: "By becoming direct owners of the projects through the use of blockchain technology, investors can earn income as the property's owner."
          },
          item2: {
            badge: "Premium projects",
            title: "Curated properties",
            desc: "Real estate investments curated by our business development team. Invest in the projects with the best return rates in the global market."
          }
        },
        options: {
          title: "Options for every investor",
          subtitle: "Invest and earn with Reblox",
          item0: {
            title: "Build-to-sell",
            desc: "Invest in developments and cash out when the property is sold. Each investor receives their capital plus the profit generated."
          },
          item1: {
            title: "Build-to-rent",
            desc: "Boost your monthly earnings generated with the build-to-rent model by investing in the most competitive locations in the market."
          }
        },
        steps: {
          title: "Real estate investing has never been simpler",
          subtitle: "FEATURES",
          item0: {
            title: "Find the project",
            desc: "Have full knowledge of the project's finances, value, location and construction schedule."
          },
          item1: {
            title: "Become an owner",
            desc: "By investing in Reblox real estate developments you take direct ownership of the property."
          },
          item2: {
            title: "Generate income",
            desc: "Enjoy and withdraw your real estate profits generated by the Reblox projects."
          }
        },
        simulate: {
          title: "Simulate your investment",
          subtitle: "Estimate how much you would earn according to your investment at the end of the term.",
          invest: "Invest",
          final_rent: "Final Rent",
          monthly: "Monthly",
          expected_irr: "Expected IRR",
          investment_term: "Investment Term",
          months: "months",
          earnings: "Earnings",
          total: "Total",
          expected_apr: "Expected APR",
          annual_earnings: "Annual Earnings",
          monthly_earnings: "Monthly Earnings"
        },
        simple_access: {
          title: "Simple access to ",
          subtitle: "PAYMENT METHODS",
          item0: {
            title: "Crypto",
            desc: "Transfer cryptocurrencies or connect your wallet to invest in any Reblox project."
          },
          item1: {
            title: "Bank transfer",
            desc: "Transfer and invest directly from a US bank account with a 0% fee."
          },
          item2: {
            title: "Credit and debit",
            desc: "Invest using your debit or credit card and get the project tokens in your wallet immediately."
          }
        },
        videos: {
          title: "What is Reblox?"
        },
        partners: {
          title: "We work with",
          subtitle: "Get to know our collaborators",
          market_data: "Market Data",
          oracle: "Oracle",
          wallet: "Wallet",
          exchange: "Exchange"
        },
        chain: {
          title: "Powered by Polygon",
          desc: "Polygon is a decentralized Ethereum scaling platform that enables developers to build scalable user-friendly dApps with low transaction fees without ever sacrificing on security. For users the network has available the \"Security as a Service\" in modular form, offered by Ethereum or a team of expert validators",
          desc2: "This makes it one of the blockchains with the largest scalability in the Blockchain ecosystem."
        },
        faq: {
          title: "Frequently Asked Questions"
        },
        waitlist: {
          title: "Join our waitlist",
          subtitle: "Be the first in getting to know our new real estate project tokenized by reblox.",
          placeholder: "your@email.com",
          success: "Successfully joined the waitlist!",
        },
        call_to_action: {
          title0: "Ready to Invest in ",
          title1: "Real Estate?",
          button: "Sign Up"
        },
      },
      about: {
        about_us: `About Us`,
        about_us_desc: `We are a Latin American Start-up focused on generating a disruptive and innovative product by simplifying access to Real Estate investments. Together with real estate developers, we tokenize secure and profitable investments to enable fractional ownership for decision making and market liquidity. `,
        about_us_desc_2: `Reblox is the result of a Real Estate company and a Web3 software factory merge.`,
        our_team: `Our Team`,
        our_team_desc: `We are a team with a strong base of entrepreneurial spirit, knowledge in blockchain and the real estate market.`,
        our_goal: `Our Goal`,
        our_goal_desc: `Our goal is to make real estate investing as simple as investing in stocks or crypto. Investing with reblox in tokenized real estate can give you cash flow, strong wealth stability, and diversification. `,
        core_values: `Core Values`,
        core_values_desc: `As a start-up, we work towards this three core values:`,
        core_values_item_0: {
          name: `Build Trust`,
          desc: `Reputation and transparency are paramount for the company. The investors are always first.`,
        },
        core_values_item_1: {
          name: `User First`,
          desc: `We engage openly with our community to create long-term focus investments.`,
        },
        core_values_item_2: {
          name: `Think Big`,
          desc: `We challenge our ideas of what’s possible in order to better meet the needs of our investors`,
        }
      },
      footer: {
        ready_to_invest: "Ready To Invest In Real Estate?",
        ready_to_invest_button: "Sign Up",
        about: "About us",
        contact: "Contact us",
        docs: "Docs"
      },
      contact: {
        contact: "Contact",
        address: "Address",
        email: "Email",
        socials: "Socials",
        form: {
          success: "Thank you for contacting us. We'll get back to you as soon as possible.",
          name: "Name",
          email: "Email",
          message: "Message",
          send_message: "Send Message"
        }
      }
    },
  },
  es: {
    translation: {
      navbar: {
        home: "Inicio",
        about: "Nosotros",
        properties: "Mercado",
        borrow: "Prestamos",
        contact: "Contacto",
        login: "Iniciar Sesión",
        signup: "Crear Cuenta"
      },
      home: {
        hero: {
          title: "Construyendo el futuro del Mercado Inmobiliario",
          subtitle: "Crowdfunding de inversión inmobiliaria mediante tokenización y blockchain",
          button: "Crear Cuenta"
        },
        subhero: {
          title: "Bienvenidos al ",
          title2: "Mercado Inmobiliario 3.0",
          subtitle: "SE DUEÑO DE TU PROPIEDAD",
        },
        proposal: {
          title: "Propuesta de Reblox",
          subtitle: "BENEFICIOS",
          item0: {
            badge: "El usuario primero",
            title: "Seguro y simple",
            desc: "Invierte sin mínimos y potencia tus ahorros con la seguridad de los inmuebles que nuestra empresa ha adquirido."
          },
          item1: {
            badge: "Innovación",
            title: "Tecnología Blockchain",
            desc: "Al convertirse en propietarios directos de los proyectos mediante el uso de la tecnología blockchain, los inversores obtienen ingresos como dueños del inmueble."
          },
          item2: {
            badge: "Proyectos premium",
            title: "Proyectos curados",
            desc: "Inversiones inmobiliarias curadas por nuestro equipo de negocios. Invierta en los proyectos con las mejores tasas de retorno en el mercado global."
          }
        },
        options: {
          title: "Opciones para cualquier inversor",
          subtitle: "Generá renta con Reblox",
          item0: {
            title: "Construir para vender",
            desc: "Invierte en desarrollos y cobra cuando se vende la propiedad. Cada inversor recibe su capital más la ganancia generada."
          },
          item1: {
            title: "Construir para alquilar",
            desc: "Aumente sus ganancias mensuales generadas con el modelo build-to-rent invirtiendo en las ubicaciones más competitivas del mercado."
          }
        },
        steps: {
          title: "Invertir en el mercado inmobiliario nunca ha sido tan simple",
          subtitle: "PASO A PASO",
          item0: {
            title: "Encuentra el proyecto",
            desc: "Tener pleno conocimiento de las finanzas, el valor, la ubicación y el cronograma de construcción del proyecto."
          },
          item1: {
            title: "Conviertete en propietario",
            desc: "Al invertir en los desarrollos inmobiliarios de Reblox, toma la propiedad directa de la propiedad."
          },
          item2: {
            title: "Genera ingresos",
            desc: "Disfruta y retira tus ganancias inmobiliarias generadas por los proyectos Reblox."
          }
        },
        simulate: {
          title: "Simula tu inversión",
          subtitle: "Estima cuánto ganarías de acuerdo a tu inversión al final del plazo.",
          invest: "Inversión",
          final_rent: "Renta Final",
          monthly: "Renta Mensual",
          expected_irr: "TIR esperada",
          investment_term: "Plazo de inversión",
          months: "meses",
          earnings: "Ganancias",
          total: "Total",
          expected_apr: "TNA esperada",
          annual_earnings: "Ganancias Anuales",
          monthly_earnings: "Ganancias Mensuales"
        },
        simple_access: {
          title: "Acceso simple a ",
          subtitle: "Métodos de Pago",
          item0: {
            title: "Cripto",
            desc: "Transfiera criptomonedas o conecte su billetera para invertir en cualquier proyecto de Reblox."
          },
          item1: {
            title: "Transferencia bancaria",
            desc: "Transfiera e invierta directamente desde una cuenta bancaria de USA con una comisión del 0%."
          },
          item2: {
            title: "Tarjeta de crédito y débito",
            desc: "Invierta usando su tarjeta de débito o crédito y obtenga los tokens del proyecto en su billetera de inmediato."
          }
        },
        videos: {
          title: "¿Qué es Reblox?"
        },
        partners: {
          title: "Trabajamos con",
          subtitle: "Colaboradores de Reblox",
          market_data: "Información de Mercado",
          oracle: "Oráculo",
          wallet: "Billetera",
          exchange: "Exchange"
        },
        chain: {
          title: "Potenciado por Polygon",
          desc: "Polygon es una plataforma descentralizada a partir de Ethereum que permite a los desarrolladores crear dApps escalables y fáciles de usar con tarifas de transacción bajas sin sacrificar nunca la seguridad. Para los usuarios la red tiene disponible el módulo “Security as a Service”, ofrecido por Ethereum o un equipo de validadores expertos.",
          desc2: "Esto la convierte en una de las redes con mayor escalabilidad en el ecosistema Blockchain."
        },
        faq: {
          title: "Preguntas Frecuentes"
        },
        waitlist: {
          title: "Únete a la lista de espera",
          subtitle: "Se el primero en conocer los proyectos inmobiliarios tokenizados por Reblox.",
          placeholder: "tu@mail.com",
          success: "Se ha anotado en la lista de espera!",
        },
        call_to_action: {
          title0: "¿Listo para invertir en el",
          title1: "Mercado Inmobiliario?",
          button: "Crear Cuenta"
        },
      },
      about: {
        about_us: `Sobre Nosotros`,
        about_us_desc: `Somos una Start-up latinoamericana enfocada en generar un producto disruptivo e innovador simplificando el acceso a las inversiones en el mercado inmobiliario. Junto con los desarrolladores de los inmuebles, tokenizamos inversiones seguras y rentables para permitir la propiedad fraccionada para la toma de decisiones y la liquidez del mercado.`,
        about_us_desc_2: `Reblox es el resultado de la fusión de una empresa inmobiliaria y una fábrica de software Web3.`,
        our_team: `Nuestro Equipo`,
        our_team_desc: `Somos un equipo con una fuerte base de espíritu emprendedor, conocimiento en blockchain y el mercado inmobiliario.`,
        our_goal: `Nuestro Objetivo`,
        our_goal_desc: `Nuestro objetivo es hacer que la inversión inmobiliaria sea líquida, accesible y segura para todos. Invertir con reblox en inmuebles tokenizados puede brindarle flujo, una sólida estabilidad patrimonial y diversificación.`,
        core_values: `Valores Fundamentales`,
        core_values_desc: `Como start-up, trabajamos hacia estos tres valores fundamentales:`,
        core_values_item_0: {
          name: `Generar confianza`,
          desc: `La reputación y la transparencia son primordiales para la empresa. Los inversores son siempre los primeros.`,
        },
        core_values_item_1: {
          name: `Usuario primero`,
          desc: `Nos involucramos abiertamente con nuestra comunidad para crear inversiones de enfoque a largo plazo.`,
        },
        core_values_item_2: {
          name: `Pensar en grande`,
          desc: `Desafiamos nuestras ideas de lo que es posible para satisfacer mejor las necesidades de nuestros inversores.`,
        }
      },
      footer: {
        ready_to_invest: "¿Listo para invertir en el Mercado Inmobiliario?",
        ready_to_invest_button: "Crear Cuenta",
        about: "Nosotros",
        contact: "Contacto",
        docs: "Docs"
      },
      contact: {
        contact: "Contacto",
        address: "Dirección",
        email: "Mail",
        socials: "Redes",
        form: {
          success: "Gracias por contactarnos. Te responderemos a la brevedad.",
          name: "Nombre",
          email: "Mail",
          message: "Mensaje",
          send_message: "Enviar Mensaje"
        }
      }
    },
  },
};

const browserLanguage = detectBrowserLanguage();

i18n.use(initReactI18next).init({
  resources,
  lng: localStorage.getItem("i18nextLng") || browserLanguage || "en",
  fallbackLng: {
    default: ["en"],
  },
  interpolation: {
    escapeValue: false,
  },
});