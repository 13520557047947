import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import Hover from 'react-3d-hover';
import featuresBgImg from "images/features_bg2.png";
import featuresImg from "images/features.png";

export interface SectionOurFeaturesProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionOurFeatures: FC<SectionOurFeaturesProps> = ({
  className = "",
  rightImg = rightImgPng,
  type = "type1",
}) => {

  const { t } = useTranslation();

  return (
    <div className={`-ml-4 -mr-4 md:-ml-24 md:-mr-24 !-mb-24 lg:!-mb-28 relative bg-[#262626]`}> 
      <div className="flex flex-col-reverse md:grid md:grid-cols-2 relative z-10">
        <ScrollAnimation animateIn="fadeIn" className={`px-8 md:px-20 py-16 md:py-24 block`}>
          <div className="space-y-4">
            <span className="uppercase text-sm text-neutral-400 tracking-widest block">{t('home.subhero.subtitle')}</span>
            <h2 className="text-white font-bold text-2xl md:text-3xl">{t('home.subhero.title')} <span className="text-primary-400">{t('home.subhero.title2')}</span></h2>
          </div>
          <ul className="space-y-10 mt-16">
            <li className="space-y-3">
              <Badge name={t('home.proposal.item0.title')} color="green" />
              {/* <span className="block text-xl font-semibold text-white">
                {t('home.proposal.item0.title')}
              </span> */}
              <span className="block mt-5 text-base text-neutral-200 dark:text-neutral-100">
                {t('home.proposal.item0.desc')}
              </span>
            </li>
            <li className="space-y-3">
              <Badge name={t('home.proposal.item1.title')} />
              {/* <span className="block text-xl font-semibold text-white">
                {t('home.proposal.item1.title')}
              </span> */}
              <span className="block mt-5 text-base text-neutral-200 dark:text-neutral-100">
                {t('home.proposal.item1.desc')}
              </span>
            </li>
            <li className="space-y-3">
              <Badge name={t('home.proposal.item2.title')} color="red" />
              {/* <span className="block text-xl font-semibold text-white">
                {t('home.proposal.item2.title')}
              </span> */}
              <span className="block mt-5 text-base text-neutral-200 dark:text-neutral-100">
                {t('home.proposal.item2.desc')}
              </span>
            </li>
          </ul>
        </ScrollAnimation>
        <div className="flex flex-col bg-[#1C1C1C] px-20 py-10 relative">
          <img src={featuresBgImg} className="hidden md:inline-block absolute -right-24 w-[35%] z-0 -bottom-24" />
          <ScrollAnimation animateIn="slideInRight" className="flex justify-center w-full text-center">
            <Hover scale={1.025} speed={800} max={13} perspective={950}>
              <NcImage src={featuresImg} containerClassName="text-center flex justify-center" className="lg:mt-10 relative z-10 w-full md:w-[75%]" />
            </Hover>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default SectionOurFeatures;
