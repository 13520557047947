import React, { FC, useContext, useState } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import ButtonThird from "shared/Button/ButtonThird";
import { AuthContext } from "contexts/AuthContext";
import NcModal from "shared/NcModal/NcModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import PirvateKeyModal from "containers/WalletPage/PrivateKeyModal";
import { useSnackbar } from "notistack";

export interface WalletProps {
  className?: string;
  data?: any;
}

const Wallet: FC<WalletProps> = ({
  className = "",
  data,
}) => {

  const { user, token, setUser } = useContext(AuthContext);
  const { _id, address, isDefault, provider } = data;
  const [ isSettingDefault, setIsSettingDefault ] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  
  const setDefault = () => {
    setIsSettingDefault(true);
    fetch(`${process.env.REACT_APP_API_URL}/users/set_default_wallet`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        userId: user?.id,
        token: token,
        walletId: _id
      })
    }).then((response) => {
      return response.json();
    }).then((json) => {
      setUser(json.data);
      enqueueSnackbar("Default wallet changed successfully", { variant: "success" });
    }).finally(() => {
      setIsSettingDefault(false);
    });
  }

  return (
    <div
      className={`!w-full nc-Wallet group relative bg-white dark:bg-neutral-900 border border-neutral-100 dark:border-neutral-800 rounded-3xl overflow-hidden hover:shadow-xl transition-shadow ${className} ${provider == "reblox" ? "cursor-pointer" : ""}`}
      data-nc-id="Wallet"
      onClick={() => { provider == "reblox" ? window.location.href="/wallet" : void(0) }}
    >
      <div className="h-full w-full flex flex-col sm:flex-row items-center px-5 py-5 md:py-3 md:space-x-3">
        <img src={`/images/wallets/${provider}.png`} className="h-16 md:h-20" />
        <div className="flex-grow p-3 sm:pr-6 flex flex-col items-start">
          <div className="space-y-2 w-full">
            <div className="flex items-center space-x-2 justify-center md:justify-start">
              {isDefault && <Badge name="Default" color="green" />}
              {provider == "reblox" ?
                <Link to="/wallet">
                  <h2 className="text-lg font-medium capitalize">
                    <span className="line-clamp-2 capitalize">{String(provider).toLowerCase()} Wallet</span>
                  </h2>
                </Link>
                :
                <h2 className="text-lg font-medium capitalize">
                  <span className="line-clamp-2 capitalize">{String(provider).toLowerCase()} Wallet</span>
                </h2>
              }
            </div>
            <span className="text-xs md:text-sm text-neutral-500 dark:text-neutral-400 w-full flex align-center justify-center md:justify-start">
              {address} <DocumentDuplicateIcon className="ml-2.5 w-4 h-4 cursor-pointer" />
            </span>
            <div className="w-14 border-b border-neutral-100 dark:border-neutral-800 "></div>
            <div className="flex w-full space-x-4 justify-center md:justify-start">
              <ButtonThird loading={isSettingDefault} onClick={(event:any) => { event.stopPropagation(); setDefault() }} disabled={isDefault} sizeClass="px-0 py-1" className={`!text-sm font-semibold text-primary-800 ${isDefault ? "opacity-40" : ""}`}>Set as default</ButtonThird>
              {provider == "reblox" && 
                <PirvateKeyModal />
              }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Wallet;
