import React from "react";
import MyRouter from "routers/index";
import { Polygon, Config, DAppProvider } from "@usedapp/core";

import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from "contexts/AuthContext";
import { SnackbarProvider } from "notistack";

import "./i18n";

function App() {
  return (
    <div className="bg-white text-base dark:bg-neutral-900 text-neutral-900 dark:text-neutral-200">
      <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID as string}>
        <DAppProvider config={config}>
          <SnackbarProvider>
            <AuthProvider>
              <MyRouter />
            </AuthProvider>
          </SnackbarProvider>
        </DAppProvider>
      </GoogleOAuthProvider>
    </div>
  );
}

const config: Config = {
  networks: [Polygon],
  autoConnect: true,
};

export default App;
