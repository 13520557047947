import SectionHero from "components/SectionHero/SectionHero";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import React from "react";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SectionOurFeatures from "components/SectionOurFeatures/SectionOurFeatures";
import SectionGridFeaturePlaces from "./SectionGridFeaturePlaces";
import SectionHowItWork from "components/SectionHowItWork/SectionHowItWork";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { TaxonomyType } from "data/types";
import SectionGridAuthorBox from "components/SectionGridAuthorBox/SectionGridAuthorBox";
import SectionGridCategoryBox from "components/SectionGridCategoryBox/SectionGridCategoryBox";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import SectionVideos from "./SectionVideos";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BgReblox from "components/BgReblox/BgReblox";
import SectionOptionsForInvestors from "components/SectionOptionsForInvestors/SectionOptionsForInvestors";
import SectionNeverBeenSimpler from "components/SectionNeverBeenSimpler/SectionNeverBeenSimpler";
import SectionSimulateInvestment from "components/SectionSimulateInvestment/SectionSimulateInvestment";
import SectionPartners from "components/SectionPartners/SectionPartners";
import SectionWeb3Simpler from "components/SectionWeb3Simpler/SectionWeb3Simpler";
import SectionCallToAction from "components/SectionCallToAction/SectionCallToAction";
import SectionChain from "components/SectionChain/SectionChain";
import SectionPayments from "components/SectionPayments/SectionPayments";
import SectionFAQ from "components/SectionFAQ/SectionFAQ";
import SectionJoinWaitList from "components/SectionJoinWaitList/SectionJoinWaitList";

function PageHome() {
  return (
    <div className="nc-PageHome relative overflow-hidden">
      {/* GLASSMOPHIN */}
      <BgGlassmorphism />
      <BgReblox />

      <div className="container relative space-y-24 mb-24 lg:space-y-28 lg:mb-28">

        <SectionHero className="pt-10 lg:pt-16 lg:pb-0" />

        <SectionOurFeatures />

        <SectionOptionsForInvestors />
        
        <SectionNeverBeenSimpler />

        <SectionSimulateInvestment />

        <SectionPayments />

        <SectionWeb3Simpler />

        <SectionPartners />

        <SectionChain />

        <SectionFAQ />

        <SectionJoinWaitList />

        <SectionCallToAction />
      </div>
    </div>
  );
}

export default PageHome;
