import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import { AuthContext } from "contexts/AuthContext";
import Logo from "shared/Logo/Logo";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { ArrowDownIcon, ArrowUpIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import NcModal from "shared/NcModal/NcModal";
import ButtonThird from "shared/Button/ButtonThird";
import PirvateKeyModal from "./PrivateKeyModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import convertNumbThousand from "utils/convertNumbThousand";
// @ts-ignore
import { getTokenBalance } from "reblox-web3-utils2";
import BigNumber from "bignumber.js";

export interface CryptoProps {
  data?: any;
}

const Crypto: FC<CryptoProps> = ({ data = {} }) => {

  const { user } = useContext(AuthContext);
  const wallet = user?.wallets[0];

  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ amount, setAmount ] = useState<number>();
  const [ amountUSD, setAmountUSD ] = useState<number>();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getTokenBalance(parseInt(process.env.REACT_APP_CHAIN_ID), wallet?.address, data.address),
      fetch(`https://api.binance.com/api/v3/ticker/price?symbol=${data.ticker == "USDT" ? "USDC" : data.ticker}USDT`, { method: "GET" })
    ]).then(async ([balance, priceResponse]) => {
      const _amount = new BigNumber(balance).shiftedBy(-data.decimals).toNumber();
      let price = 1;
      if (data.ticker != "USDT") {
        const priceJSON = await priceResponse.json();
        if (priceJSON?.price) {
          price = priceJSON?.price;
        }
      }
      setAmount(_amount);
      setAmountUSD(_amount * price);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [user]);
  
  return (
    <div
      className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] flex-grow justify-between`}
      data-nc-id="CardCategoryBox1"
    >
      {/* <Badge
        className="absolute right-2 top-2"
        color="gray"
        name={convertNumbThousand(count)}
      /> */}
      <div className="flex items-center">
        <div className="relative flex-shrink-0 w-12 h-12 md:w-16 md:h-16 rounded-full overflow-hidden">
          <NcImage src={`/images/tokens/${data.ticker}.png`} containerClassName="absolute inset-0" />
        </div>
        <div className="ml-4 flex-grow overflow-hidden">
          <h2 className="text-base font-medium">
            <span className="line-clamp-1">{data.ticker}</span>
          </h2>
          <span className={`block mt-0 text-sm text-neutral-500 dark:text-neutral-400`}>
            {data.name}
          </span>
        </div>
      </div>
      <div className="overflow-hidden text-right">
        <h2 className="font-semibold text-right">
          {isLoading ? <i className="las la-circle-notch la-spin"></i> : `$${convertNumbThousand(amountUSD)}`}
        </h2>
        <span className={`block mt-0 text-sm text-neutral-500 dark:text-neutral-400 text-right`}>
          {isLoading ? <i className="las la-circle-notch la-spin"></i> : convertNumbThousand(amount)}
        </span>
      </div>
    </div>
  );
};

export default Crypto;
