import React, { ReactNode, useContext, useEffect, useState } from "react";

type AuthContextType = {
	isLoggedIn: boolean;
	user: any;
	token: String;
	login: (token: String, user: any) => void;
	logout: () => void;
	setUser: (user: any) => void;
};

const AuthContext = React.createContext<AuthContextType>({
	isLoggedIn: false,
} as AuthContextType);

interface AuthProviderProps {
	children: ReactNode;
}

const AuthProvider = ({ children }: AuthProviderProps) => {

	const [isLoggedIn, setIsLoggedIn] = useState<boolean>(localStorage.getItem("loggedIn") ? JSON.parse(localStorage.getItem("loggedIn") as string) : false);
	const [user, setUser] = useState<any>(localStorage.getItem("user") ? JSON.parse(localStorage.getItem("user") as string) : null);
	const [token, setToken] = useState<any>(localStorage.getItem("token") ? localStorage.getItem("token") : null);

	const login = (token: String, user: any) => {
		localStorage.setItem("token", token as string);
		localStorage.setItem("user", JSON.stringify(user));
		localStorage.setItem("loggedIn", "true");
		setToken(token);
		setUser(user);
		setIsLoggedIn(true);
	}

	const logout = () => {
		localStorage.removeItem("token");
		localStorage.removeItem("user");
		localStorage.removeItem("loggedIn");
		setToken(null);
		setUser(null);
		setIsLoggedIn(false);
	}

	useEffect(() => {
		if (token && user) {
			fetch(`${process.env.REACT_APP_API_URL}/auth/is_logged_in`, {
				headers: { "Content-Type": "application/json" },
				method: "POST",
				credentials: "include",
				body: JSON.stringify({
					token: token, 
					userId: user?._id
				}),
			}).then(r => {
				if (r.status == 200) {
					const token = r.headers.get('x-auth-token');
					r.json().then(user => {
						if (token) {
							login(token, user);
						}
					});
				} else {
					logout();
				}
			}).catch((err: any) => {
				logout();
			});
		}
	}, []);

	return (
		<AuthContext.Provider
			value={{ 
				isLoggedIn: isLoggedIn,
				login: login,
				logout: logout,
				user: user,
				token: token,
				setUser: setUser 
			}}
		>
			{children}
		</AuthContext.Provider>
	);
};

export { AuthContext, AuthProvider };