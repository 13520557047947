import React, { useContext } from "react";
import { BrowserRouter, Switch, Route } from "react-router-dom";
import { Page } from "./types";
import ScrollToTop from "./ScrollToTop";
import Footer from "shared/Footer/Footer";
import PageHome from "containers/PageHome/PageHome";
import Page404 from "containers/Page404/Page404";
import ListingStayPage from "containers/ListingStayPage/ListingStayPage";
import ListingStayMapPage from "containers/ListingStayPage/ListingStayMapPage";
import ListingExperiencesPage from "containers/ListingExperiencesPage/ListingExperiencesPage";
import ListingExperiencesMapPage from "containers/ListingExperiencesPage/ListingExperiencesMapPage";
import ListingStayDetailPage from "containers/ListingDetailPage/ListingStayDetailPage";
import ListingExperiencesDetailPage from "containers/ListingDetailPage/ListingExperiencesDetailPage";
import ListingCarPage from "containers/ListingCarPage/ListingCarPage";
import ListingCarMapPage from "containers/ListingCarPage/ListingCarMapPage";
import ListingCarDetailPage from "containers/ListingDetailPage/ListingCarDetailPage";
import CheckOutPage from "containers/CheckOutPage/CheckOutPage";
import PayPage from "containers/PayPage/PayPage";
import AuthorPage from "containers/AuthorPage/AuthorPage";
import AccountPage from "containers/AccountPage/AccountPage";
import AccountPage2 from "containers/AccountPage2/AccountPage";
import AccountPass from "containers/AccountPage2/AccountPass";
import AccountSavelists from "containers/AccountPage2/AccountSavelists";
import AccountBilling from "containers/AccountPage2/AccountBilling";
import PageContact from "containers/PageContact/PageContact";
import PageAbout from "containers/PageAbout/PageAbout";
import PageSignUp from "containers/PageSignUp/PageSignUp";
import PageLogin from "containers/PageLogin/PageLogin";
import PageSubcription from "containers/PageSubcription/PageSubcription";
import BlogPage from "containers/BlogPage/BlogPage";
import BlogSingle from "containers/BlogPage/BlogSingle";
import PageAddListing1 from "containers/PageAddListing1/PageAddListing1";
import PageAddListing2 from "containers/PageAddListing1/PageAddListing2";
import PageAddListing3 from "containers/PageAddListing1/PageAddListing3";
import PageAddListing4 from "containers/PageAddListing1/PageAddListing4";
import PageAddListing5 from "containers/PageAddListing1/PageAddListing5";
import PageAddListing6 from "containers/PageAddListing1/PageAddListing6";
import PageAddListing7 from "containers/PageAddListing1/PageAddListing7";
import PageAddListing8 from "containers/PageAddListing1/PageAddListing8";
import PageAddListing9 from "containers/PageAddListing1/PageAddListing9";
import PageAddListing10 from "containers/PageAddListing1/PageAddListing10";
import PageHome2 from "containers/PageHome/PageHome2";
import ListingRealEstateMapPage from "containers/ListingRealEstatePage/ListingRealEstateMapPage";
import ListingRealEstatePage from "containers/ListingRealEstatePage/ListingRealEstatePage";
import SiteHeader from "containers/SiteHeader";
import ListingFlightsPage from "containers/ListingFlightsPage/ListingFlightsPage";
import FooterNav from "components/FooterNav";
import useWindowSize from "hooks/useWindowResize";
import PageHome3 from "containers/PageHome/PageHome3";
import { AuthContext } from "contexts/AuthContext";
import PageCreateAccount from "containers/PageCreateAccount/PageCreateAccount";
import PageForgotPassword from "containers/PageForgotPassword/PageForgotPassword";
import PropertiesPage from "containers/PropertiesPage/PropertiesPage";
import WalletPage from "containers/WalletPage/WalletPage";

export const pages: Page[] = [
  { path: "/", exact: true, component: PageHome, auth: false },
  { path: "/#", exact: true, component: PageHome, auth: false },
  { path: "/checkout", component: CheckOutPage, auth: true },
  { path: "/pay-done", component: PayPage, auth: true },
  { path: "/contact", component: PageContact, auth: false },
  { path: "/about", component: PageAbout, auth: false },
  { path: "/signup", component: PageCreateAccount, auth: false },
  { path: "/forgot-password", component: PageForgotPassword, auth: false },
  { path: "/login", component: PageLogin, auth: false },
  { path: "/properties", component: PropertiesPage, auth: false },
  { path: "/property/:slug", component: ListingStayDetailPage, auth: true },
  { path: "/account", component: AccountPage, auth: true },
  { path: "/wallet", component: WalletPage, auth: true },
];

export const pagesProd: Page[] = [
  { path: "/", exact: true, component: PageHome, auth: false },
  { path: "/#", exact: true, component: PageHome, auth: false },
  { path: "/contact", component: PageContact, auth: false },
  { path: "/about", component: PageAbout, auth: false },
];

const Routes = () => {
  const WIN_WIDTH = useWindowSize().width || window.innerWidth;
  
  const { isLoggedIn, user } = useContext(AuthContext);

  return (
    <BrowserRouter basename="">
      <ScrollToTop />
      <SiteHeader />

      <Switch>
        {(process.env.REACT_APP_NODE_ENV == "development" ? pages : pagesProd).map(({ component, path, exact, auth }) => {
          return (
            <Route
              key={path}
              component={
                auth && isLoggedIn && !user?.country ?
                  PageCreateAccount :
                  auth && !isLoggedIn ? 
                    PageLogin : 
                    isLoggedIn && (component == PageLogin) ? PageHome : component}
              exact={!!exact}
              path={path}
            />
          );
        })}
        <Route component={Page404} />
      </Switch>

      {(WIN_WIDTH < 768) && <FooterNav />}
      <Footer />
    </BrowserRouter>
  );
};

export default Routes;
