import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useContext, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import { AuthContext } from "contexts/AuthContext";
import Wallets from "./Wallets";

export interface AccountPageProps {
  className?: string;
}

const AccountPage: FC<AccountPageProps> = ({ className = "" }) => {
  let [tabs] = useState([
    { name: "Account Overview", component: <></>}, 
    { name: "Wallets", component: <Wallets />},
    { name: "Refer and Earn", component: <></>}, 
    { name: "Settings", component : <></>},
  ]);

  const { user } = useContext(AuthContext);

  const renderSidebar = () => {
    return (
      <div className=" w-full flex flex-col items-center text-center sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-7 px-0 sm:p-6 xl:p-8">
        <Avatar
          hasChecked
          hasCheckedClass="w-6 h-6 -top-0.5 right-2"
          sizeClass="w-24 h-24"
        />

        <div className="space-y-2 text-center flex flex-col items-center">
          <h2 className="text-2xl md:text-3xl font-semibold">{user?.first_name} {user?.last_name}</h2>
          <div className="text-neutral-500 dark:text-neutral-400">{user?.email}</div>
          <div className="flex space-x-2 items-center">
            <img className="h-3 rounded-sm" src={`https://countryflagsapi.com/png/${String(user?.country).toLowerCase()}`} />
            <div>{user?.country}</div>
          </div>
        </div>

        
        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>



        <div className="border-b border-neutral-200 dark:border-neutral-700 w-14"></div>

        <div className="space-y-2 text-center flex flex-col items-center">
          <div className="text-neutral-500 dark:text-neutral-400 text-sm">Account created: {new Date(user?.createdAt).toLocaleDateString()}</div>
        </div>

      </div>
    );
  };

  const renderSection1 = () => {
    return (
      <div className="listingSection__wrap p-0">

        <div>
          <Tab.Group>
            <Tab.List className="flex overflow-x-auto hiddenScrollbar border-b">
              {tabs.map((item, index) => (
                <Tab key={index} as={Fragment}>
                  {({ selected }) => (
                    <span
                      className={`block py-5 md:py-8 border-b-2 border-transparent flex-shrink-0 cursor-pointer px-7 ${
                        selected
                          ? "!border-primary-500"
                          : ""
                      } `}
                    >
                      {item.name}
                    </span>
                  )}
                </Tab>
              ))}
            </Tab.List>
            <Tab.Panels className="px-4 md:px-6 py-7">
              {tabs.map((item, index) => (
                <Tab.Panel>
                  {item.component}
                </Tab.Panel>
              ))}
            </Tab.Panels>
          </Tab.Group>
        </div>
      </div>
    );
  };

  return (
    <div className={`nc-AccountPage ${className}`} data-nc-id="AccountPage">
      <Helmet>
        <title>Reblox - Real Estate</title>
      </Helmet>
      <main className="container mt-12 mb-24 lg:mb-32 flex flex-col lg:flex-row !px-0">
        <div className="block flex-grow mb-12 lg:mb-0">
          <div className="lg:sticky lg:top-24">{renderSidebar()}</div>
        </div>
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pl-10 flex-shrink-0">
          {renderSection1()}
        </div>
      </main>
    </div>
  );
};

export default AccountPage;
