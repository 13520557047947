import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import { useTranslation } from "react-i18next";
import ScrollAnimation from 'react-animate-on-scroll';
import option1Img from "images/option-1.png";
import option2Img from "images/option-2.png";
import web3SimplerBgLeftImg from "images/web3simpler_bg_left.png";
import web3SimplerBgRightImg from "images/web3simpler_bg_right.png";

export interface SectionOptionsForInvestorsProps {
  className?: string;
}

const SectionOptionsForInvestors: FC<SectionOptionsForInvestorsProps> = ({
  className = "",
}) => {

  const { t } = useTranslation();

  const data = [
    {
      id: 1,
      img: option1Img,
      title: t('home.options.item0.title'),
      desc: t('home.options.item0.desc'),
    },
    {
      id: 2,
      img: option2Img,
      title: t('home.options.item1.title'),
      desc: t('home.options.item1.desc'),
    },
  ];

  return (
    <div className={`nc-SectionOptionsForInvestors -ml-4 -mr-4 md:-ml-24 md:-mr-24 bg-[#348282] relative`}> 
      <div className={`p-12 py-24 !md:p-24 text-white ${className}`}>
        <Heading className="text-white" classNameDesc="text-neutral-100" isCenter desc={t('home.options.subtitle')}>
          {t('home.options.title')}
        </Heading>
        <div className="mt-20 relative grid md:grid-cols-2 gap-0">
          {data.map((item, index) => (
            <ScrollAnimation animateIn="fadeIn"
              key={item.id}
              className="relative flex flex-col items-center max-w-xs mx-auto items-start"
            >
              <NcImage
                className="object-contain w-full h-full"
                containerClassName="mb-8 h-[75px] w-auto md:h-[100px] mx-auto"
                src={item.img}
              />
              <div className="text-center">
                <h3 className="text-xl font-semibold">{item.title}</h3>
                <span className="block mt-5 text-sm text-neutral-100 dark:text-neutral-100">
                  {item.desc}
                </span>
              </div>
            </ScrollAnimation>
          ))}
        </div>
      </div>
      {/* <div className="triangle triangle-up bg-white absolute bottom-0 bg-[#348282]"></div> */}
      <img src={web3SimplerBgLeftImg} className="absolute left-0 top-0 h-full" />
      <img src={web3SimplerBgLeftImg} className="absolute right-0 top-0 h-full" style={{ transform: "rotateY(180deg)" }} />
    </div>
  );
};

export default SectionOptionsForInvestors;
