import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import FAQ from "./FAQ";
import { useTranslation } from "react-i18next";

export interface SectionFAQProps {
  className?: string;
}

const SectionFAQ: FC<SectionFAQProps> = ({
  className = "",
}) => {

  const { t, i18n } = useTranslation();
  
  const FAQs = [
    {
      title_br: "",
      title_es: "¿Qué es Reblox?",
      title_en: "What is Reblox?",
      desc_br: <p></p>,
      desc_es: <p>Nuestra misión es llevar la inversión inmobiliaria al mundo digital, y lo hacemos tokenizando inversiones inmobiliarias de alta calidad.
      <br />Los tokens inmobiliarios en Reblox representan la propiedad de las inversiones inmobiliarias subyacentes.
      </p>,
      desc_en: <p>Our mission is to bring real estate investing into the digital world, and we do this by tokenizing high-quality,  real estate investments.
      <br />Real estate tokens on Reblox represent ownership in underlying real estate investments.</p>
    },
    {
      title_br: "",
      title_es: "¿Cuáles son los beneficios de los bienes raíces tokenizados?",
      title_en: "What are the benefits of tokenized real estate?",
      desc_br: <p></p>,
      desc_es: <p>La tokenización de activos inmobiliarios viene con una serie de beneficios, que incluyen:
      <br />
      <br />
      Reducción del riesgo de inversión con la diversificación del portfolio.<br/>
      Mayor liquidez al permitir que los tokens se negocien con tecnología blockchain compatible. <br/>
      Menores costos de transacción y tiempos de procesamiento más rápidos.<br/>
      Capacidad de obtener ingresos por alquiler directamente en una billetera cripto o fiduciaria.<br/>
      Mejora en la transparencia con mantenimiento de los registros seguros y visibles.<br/>
      Bajo ticket de entrada para una inversión inmobiliaria.<br/>
      Los procesos digitalizados hacen que las transacciones sean más baratas y transparentes con tiempos de procesamiento más rápidos.
      </p>,
      desc_en: <p>Real estate asset tokenization comes with a number of benefits, which include:
      <br/>
      <br/>
      Reduced investment risk with portfolio diversification<br />
      Higher liquidity by allowing tokens to be traded with compliant blockchain technology<br />
      Lower transaction costs and faster processing times<br />
      Ability to earn rental income straight to a crypto or fiat wallet<br />
      Improved transparency with secure and visible record-keeping<br />
      Reduced entrance ticket to a real estate investment<br />
      Digitized processes make the transactions cheaper and transparent with faster processing times.
      </p>
    },
    {
      title_br: "",
      title_es: "¿Cómo funciona la tokenización en el Mercado Inmobiliario?",
      title_en: "How does tokenization in Real Estate work?",
      desc_br: <p></p>,
      desc_es: <p>La tokenización crea activos digitales (tokens) que representan la propiedad fraccionada de un activo.
      <br/>
      <br/>
      La tokenización se realiza en una blockchain, un libro contable compartido para registrar transacciones y activos. Los tokens respaldados por los inmuebles contienen todas las reglas comerciales y el cumplimiento de las regulaciones donde se encuentra el activo subyacente.
      </p>,
      desc_en: <p>Tokenization creates digital assets (tokens) that represent fractional ownership of an asset.
      <br/>
      <br/>
      Tokenization is done on a blockchain - a shared ledger for recording transactions and assets. Tokens backed by real estate contain all the business rules and compliance in the regulations where the underlying asset is located
      </p>
    },
    {
      title_br: "",
      title_es: "¿Cuál es la inversión máxima y mínima?",
      title_en: "What is the maximum and minimum investment?",
      desc_br: <p></p>,
      desc_es: <p>La inversión mínima establecida por Reblox es de $50 por token por cada inversión inmobiliaria. 
      Para cada propiedad, el número máximo de tokens de una propiedad que cada inversor puede poseer es del 30%.
      <br/>
      <br/>
      Este límite existe por razones de Gobernanza para garantizar un sistema de votación justo.
      </p>,
      desc_en: <p>The minimum investment setted by Reblox is $50.
      For each property, the maximum number of tokens of a property each investor can own is 30%.  
      <br/>
      <br/>
      This limit is in place for Governance reasons in order to ensure a fair voting system.     
      </p>
    },
    {
      title_br: "",
      title_es: "¿Quién puede invertir?",
      title_en: "Who is allowed to invest?",
      desc_br: <p></p>,
      desc_es: <p>Tanto los inversores estadounidenses como los no estadounidenses pueden comprar tokens.
      <br/>
      <br/>
      Si es de un país sancionado, no podrá comprar tokens debido a las restricciones de la Oficina de Control de Activos Extranjeros (OFAC). Los países sancionados incluyen los Balcanes, Bielorrusia, Birmania, Costa de Marfil (Costa de Marfil), Cuba, República Democrática del Congo, Irán, Irak, Liberia, Corea del Norte, Rusia, Sudán, Siria y Zimbabue.
      </p>,
      desc_en: <p>Both US and non-US investors are allowed to purchase tokens.
      <br/>
      <br/>
      If you are from a sanctioned country, you will not be able to purchase tokens due to restrictions by the Office of Foreign Assets Control (OFAC). Sanctioned countries include Balkans, Belarus, Burma, Côte D’Ivoire (Ivory Coast), Cuba, Democratic Republic of Congo, Iran, Iraq, Liberia, North Korea, Russia, Sudan, Syria, and Zimbabwe.
      </p>
    },
    {
      title_br: "",
      title_es: "¿Qué es Metamask? ¿Necesito uno?",
      title_en: "What is Metamask? Do I need one?",
      desc_br: <p></p>,
      desc_es: <p>Metamask es una billetera descentralizada que se acepta en Reblox. No es obligatorio invertir con metamask ni ningún monedero descentralizado ya que tenemos disponible la  Reblox Wallet para aquellos usuarios con menos experiencia en Web3.</p>,
      desc_en: <p>Metamask is a decentralized wallet that is accepted on Reblox. It is not mandatory to invest with metamask or any decentralized wallet since we have the Reblox wallet available for those users with less experience in Web3.</p>
    },
    {
      title_br: "",
      title_es: "¿Quién tiene legalmente la propiedad de estos inmuebles?",
      title_en: "Who legally has ownership of these properties?",
      desc_br: <p></p>,
      desc_es: <p>Cuando compra tokens en Reblox, está comprando directamente una participación en la LLC dueña del activo inmobiliario.
      <br/>
      <br/>
      Por ejemplo, si compra el 1 % de los tokens de una propiedad, entonces tendrá derecho al 1 % de los intereses económicos del activo a lo largo del tiempo, que pueden incluir ingresos por alquiler o apreciación del valor de la propiedad.
      </p>,
      desc_en: <p>When you purchase tokens of a Reblox project, you are directly buying a membership interest in the individual LLC that owns that property asset.
      <br/>
      <br/>
      For example, if you purchase 1% of the tokens in a single home offering, you would then be entitled to 1% of the economic interests of the asset over time, which may include income from rent or property value appreciation.</p>
    },
    {
      title_br: "",
      title_es: "¿Qué sucede si la inversión para llevar a cabo el desarrollo no está totalmente financiada?",
      title_en: "What happens if the investment to carry out the development is not fully funded?",
      desc_br: <p></p>,
      desc_es: <p>Si parece que el desarrollo no estará completamente financiado para la fecha de cierre, el período de inversión podría extenderse.
      <br />
      <br />
      Transcurrido ese plazo, si aún no se financia la inversión, no se realizará el desarrollo y se devolverá el dinero a los partícipes.
      </p>,
      desc_en: <p>If it appears that the development won't be fully funded by the closing date, the investment period could be extended.
      <br />
      <br />
      After that period, if the investment is still not funded, the development will not be carried out and the money will be returned to the paricipants.</p>
    },
    {
      title_br: "",
      title_es: "¿Cómo puedo hacer un seguimiento del rendimiento de mi propiedad?",
      title_en: "How can I track the performance of my property?",
      desc_br: <p></p>,
      desc_es: <p>Todos los propietarios tienen su propio tablero de inversiones, donde pueden ver cuántos tokens tienen y su valor principal actual, que se ajustará automáticamente con el tiempo durante la vida de la inversión.
      <br/>
      <br/>
      Todos los usuarios podran tambien ver los ingresos generados en su cuenta junto con registros de transacciones históricas.
      </p>,
      desc_en: <p>All owners have their own property dashboard, where they can all see how many tokens they hold and their current principal value, which will adjust automatically over time in the life of the investment.
      <br/>
      <br/>
      All users we’ll be able to see income added to your account along with records of historical transactions.</p>
    },
  ];

  return (
    <div className={`nc-SectionFAQ relative bg-primary-50 bg-opacity-10`}> 
      <div className="px-4 py-16 md:p-16 text-center shadow-lg rounded-3xl text-center -ml-2 -mr-2 md:ml-0 md:mr-0">
        <h2 className="font-bold text-3xl text-center mb-12">Frequently Asked Questions</h2>

        <div className="space-y-4">
          
          {FAQs.map((item,index) => (
            <FAQ key={index} title={item[`title_${i18n.language}`]} desc={item[`desc_${i18n.language}`]} />
          ))}

        </div>
      </div>
    </div>
  );
};

export default SectionFAQ;
