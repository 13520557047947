import React, { FC } from "react";
import Heading from "components/Heading/Heading";
import { useTranslation } from "react-i18next";

export interface Statistic {
  id: string;
  heading: string;
  subHeading: string;
}

export interface SectionCoreValuesProps {
  className?: string;
}

const SectionCoreValues: FC<SectionCoreValuesProps> = ({ className = "" }) => {

  const { t } = useTranslation();

  const coreValues: Statistic[] = [
    {
      id: "1",
      heading: t(`about.core_values_item_0.name`),
      subHeading: t(`about.core_values_item_0.desc`),
    },
    {
      id: "2",
      heading: t(`about.core_values_item_1.name`),
      subHeading: t(`about.core_values_item_1.desc`),
    },
    {
      id: "3",
      heading: t(`about.core_values_item_2.name`),
      subHeading: t(`about.core_values_item_2.desc`),
    },
  ];

  return (
    <div className={`nc-SectionCoreValues relative ${className}`}>
      <Heading
        desc={t(`about.core_values_desc`)}
      >
        {t(`about.core_values`)}
      </Heading>
      <div className="grid md:grid-cols-2 gap-6 lg:grid-cols-3 xl:gap-8">
        {coreValues.map((item) => (
          <div
            key={item.id}
            className="p-6 bg-primary-50 rounded-2xl dark:border-neutral-800"
          >
            <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
              {item.heading}
            </h3>
            <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
              {item.subHeading}
            </span>
          </div>
        ))}
      </div>
    </div>
  );
};

export default SectionCoreValues;
