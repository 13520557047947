import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useTranslation } from "react-i18next";
import callToActionImg from "images/call_to_action_bg.png";

export interface SectionCallToActionProps {
  className?: string;
}

const SectionCallToAction: FC<SectionCallToActionProps> = ({
  className = "",
}) => {
  const { t } = useTranslation();

  return (
    <div className={`nc-SectionCallToAction -ml-4 -mr-4 md:-ml-24 md:-mr-24 bg-[#262626] relative !-mb-24 lg:!-mb-28`}> 
      <div className={`space-y-8 p-8 py-36 text-center relative z-10 flex flex-col items-center !md:p-24 text-white md:mx-[30%] ${className}`}>
        <h2 className="text-center text-white font-bold text-5xl md:text-6xl max-w-sm mx-auto leading-tight">{t('home.call_to_action.title0')}<span className="block md:inline text-primary-400">{t('home.call_to_action.title1')}</span></h2>
        <hr className="w-[50%] mx-auto" />
        {/* <Logo className="mx-auto w-[50%]" isLight={true} /> */}
        <ButtonSecondary className="w-fit mx-auto !px-10" href={"/signup"}>{t('home.call_to_action.button')}</ButtonSecondary>
      </div>
      <img src={callToActionImg} className="hidden md:inline-block absolute z-0 top-0 right-0 object-cover h-full translate-x-1/2" />
      <img src={callToActionImg} className="hidden md:inline-block absolute z-0 top-0 left-0 object-cover h-full -translate-x-1/2" />
    </div>
  );
};

export default SectionCallToAction;
