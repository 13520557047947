import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import { AuthContext } from "contexts/AuthContext";
import Logo from "shared/Logo/Logo";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { ArrowDownIcon, ArrowUpIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import NcModal from "shared/NcModal/NcModal";
import ButtonThird from "shared/Button/ButtonThird";
import PirvateKeyModal from "./PrivateKeyModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import Crypto from "./Crypto";
import Property from "./Property";

export interface WalletPageProps {
  className?: string;
}

const WalletPage: FC<WalletPageProps> = ({ className = "" }) => {

  const { user, token } = useContext(AuthContext);
  const wallet = user?.wallets[0];

  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ cryptos, setCryptos ] = useState<any>([]);
  const [ properties, setProperties ] = useState<any>([]);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/tokens/get_tokens`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        userId: user?.id,
        token: token
      })
    }).then((response) => {
      return response.json();
    }).then((json) => {
      setCryptos(json.data);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/properties/get_properties`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        userId: user?.id,
        token: token
      })
    }).then((response) => {
      return response.json();
    }).then((json) => {
      setProperties(json.data);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);
  
  return (
    <div className={`nc-WalletPage ${className}`} data-nc-id="WalletPage">
      <Helmet>
        <title>Reblox - Real Estate</title>
      </Helmet>
      <main> 
        <div className="flex flex-col space-y-10 mt-12 mb-24 lg:mb-32 px-4">
          <div className="container flex flex-col lg:flex-row !px-0 justify-between items-center space-y-8 md:space-y-0">
            <div>
              <Logo className="w-44 md:w-64 mb-2" />
              <span className="text-neutral-500 text-sm md:text-xl -ml-10 md:-ml-14 mt-5">Wallet</span>
            </div>
            <div className="flex justify-center items-center md:justify-end space-x-2">
              <ButtonPrimary><ArrowUpIcon className="w-5 h-5 mr-2.5" /> Send</ButtonPrimary>
              <NcModal
                renderTrigger={(openModal) => (<ButtonSecondary onClick={() => { openModal() }} ><ArrowDownIcon className="w-5 h-5 mr-2.5" /> Receive</ButtonSecondary>)}
                renderContent={() => 
                  <div className="flex flex-col space-y-5">
                    <p>To receive cryptocurrency in the Reblox Wallet just transfer funds via <b>Polygon Mainnet</b> to your address:</p>
                    <code className="break-all p-3 bg-primary-50 rounded-lg">{wallet.address}</code>
                    <span className="text-neutral-500">To learn more click <a href="#">here</a></span>
                  </div> 
                }
                modalTitle="Receive Crypto"
              />
            </div>
          </div>
          
          <div className="container relative p-5 bg-neutral-100 dark:bg-black dark:bg-opacity-20 rounded-2xl">
            <div className={`nc-SectionBecomeAnAuthor relative flex flex-col md:flex-row justify-between md:items-center`} data-nc-id="SectionBecomeAnAuthor">
              <div>
                <h2 className="font-semibold text-lg sm:text-xl mb-1">
                  Wallet address
                </h2>
                <h4 className="text-neutral-500 text-xs sm:text-base flex space-x-2 items-center">
                  <span>{wallet.address}</span> <DocumentDuplicateIcon className="w-5 h-5 cursor-pointer" />
                </h4>
              </div>
              <div>
                <PirvateKeyModal />
              </div>
            </div>
          </div>
          
          <div className="container p-0 relative grid grid-cols-1 md:grid-cols-3 gap-3 md:gap-4">
            {cryptos.map((item, key) => (
              <Crypto key={key} data={item} />
            ))}
            {properties.map((item, key) => (
              <Property key={key} data={item} />
            ))}
          </div>
        </div>
      </main>
    </div>
  );
};

export default WalletPage;
