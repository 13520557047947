import Heading from "components/Heading/Heading";
import React, { FC, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface FAQProps {
  className?: string;
  title?: string;
  desc?: React.ReactNode;
}

const FAQ: FC<FAQProps> = ({
  className = "",
  title = "",
  desc = ""
}) => {

  const [isOpened, setIsOpened] = useState<boolean>(false);

  return (
    <div onClick={() => setIsOpened(!isOpened)} className="cursor-pointer p-6 md:px-10 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]">
      <div className="flex justify-between items-center space-x-2">
        <h2 className="font-semibold text-base text-left">{title}</h2>
        <span className="text-primary-400 font-semibold text-xl leading-none">{isOpened ? "-" : "+"}</span>
      </div>
      {isOpened && <div className="mt-5 text-neutral-600 text-left">
        {desc}
      </div>}
    </div>
  );
};

export default FAQ;
