import { SocialType } from "shared/SocialsShare/SocialsShare";
import React, { FC } from "react";

export interface SocialsList1Props {
  className?: string;
  itemClassName?: string;
}

const socials: SocialType[] = [
  { name: "Twitter", icon: "lab la-twitter", href: "https://twitter.com/Reblox_" },
  { name: "Discord", icon: "lab la-discord", href: "https://discord.gg/3NNuyYKH" },
  { name: "Linkedin", icon: "lab la-linkedin", href: "#" },
  { name: "Youtube", icon: "lab la-youtube", href: "https://www.youtube.com/channel/UC6fsVOgotgbjpL1mT6ri0dg/videos" },
  { name: "Instagram", icon: "lab la-instagram", href: "https://www.instagram.com/reblox.ai/" },
  { name: "Medium", icon: "lab la-medium", href: "https://medium.com/@reblox.ai" },
];

const SocialsList1: FC<SocialsList1Props> = ({ className = "space-y-2.5", itemClassName = "" }) => {
  const renderItem = (item: SocialType, index: number) => {
    return (
      <a
        href={item.href}
        className={`flex items-center justify-center space-x-2 group text-base text-white leading-none ${itemClassName}`}
        key={index}
        target="_blank"
      >
        <i className={item.icon}></i>
        <span className="text-base">{item.name}</span>
      </a>
    );
  };

  return (
    <div className={`nc-SocialsList1 ${className}`} data-nc-id="SocialsList1">
      {socials.map(renderItem)}
    </div>
  );
};

export default SocialsList1;
