import { shortenAddress, useChainMeta, useEthers } from "@usedapp/core";
import CoinbaseWalletSDK from "@coinbase/wallet-sdk";
import WalletConnect from "@walletconnect/web3-provider";
import BigNumber from "bignumber.js";
import clsx from "clsx";
import { ComponentPropsWithoutRef, useContext, useEffect } from "react";
// import { useSwitchToDefaultNetwork } from "hooks/useSwitchToDefaultNetwork";
import Web3Modal from "web3modal";
import { GlobeAltIcon } from "@heroicons/react/outline";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import { TorusConnector } from "@web3-react/torus-connector";

export const ConnectWalletButton = ({ noShow = false, setProvider = undefined, autoDeactivate = false }) => {
  const { activate, account, deactivate, chainId } = useEthers();
//   const switchToDefaultNetwork = useSwitchToDefaultNetwork();

  const handleConnectWallet = async () => {
    try {
      const provider = await web3Modal.connect();
      await activate(provider);
      console.log(provider);
      // switchToDefaultNetwork();
      setProvider && setProvider(provider?.isMetaMask ? "metamask" : provider?.isCoinbaseWallet ? "coinbase" : provider?.isTorusWallet ? "torus" : "walletconnect");
    } catch (e) {
      console.error(e);
    }
  };

  const handleDeactivate = () => {
    deactivate();
    web3Modal.clearCachedProvider();
  };

  useEffect(() => {
    if (web3Modal.cachedProvider && !account) {
      handleConnectWallet();
    }
    if (autoDeactivate) {
      deactivate();
    }
  }, []);

  if (noShow) return <></>;

  // if (!account) {
    return (
      <ButtonPrimary className="wallet" onClick={handleConnectWallet}>
        <span className="mr-3">Connect</span>
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path
            d="M18.04 13.55C17.62 13.96 17.38 14.55 17.44 15.18C17.53 16.26 18.52 17.05 19.6 17.05H21.5V18.24C21.5 20.31 19.81 22 17.74 22H6.26C4.19 22 2.5 20.31 2.5 18.24V11.51C2.5 9.44001 4.19 7.75 6.26 7.75H17.74C19.81 7.75 21.5 9.44001 21.5 11.51V12.95H19.48C18.92 12.95 18.41 13.17 18.04 13.55Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M2.5 12.4101V7.8401C2.5 6.6501 3.23 5.59006 4.34 5.17006L12.28 2.17006C13.52 1.70006 14.85 2.62009 14.85 3.95009V7.75008"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M22.5588 13.9702V16.0302C22.5588 16.5802 22.1188 17.0302 21.5588 17.0502H19.5988C18.5188 17.0502 17.5288 16.2602 17.4388 15.1802C17.3788 14.5502 17.6188 13.9602 18.0388 13.5502C18.4088 13.1702 18.9188 12.9502 19.4788 12.9502H21.5588C22.1188 12.9702 22.5588 13.4202 22.5588 13.9702Z"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
          <path
            d="M7 12H14"
            stroke="currentColor"
            strokeWidth="1.5"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </ButtonPrimary>
    );
  // }

  // if (chainId != CHAIN_ID) {
  //   return (
  //     <ButtonPrimary
  //       className="text-danger !bg-red-800 hover:!bg-red-800 wallet"
  //       onClick={switchToDefaultNetwork}
  //       // style={{ paddingLeft: "15px" }}
  //     >
  //       <span className="mr-3">Wrong Network</span>
  //       <GlobeAltIcon className="w-5 h-5" />
  //     </ButtonPrimary>
  //   );
  // }

  // return (
  //   // <WalletConnectedButton
  //   //   account={account}
  //   //   chainId={chainId}
  //   //   onClick={handleDeactivate}
  //   // />
  //   <div className="ml-4" onClick={handleDeactivate}>
  //     <p className="text-sm font-medium ">{"Disconnect"}</p>
  //   </div>
  // );
};

const infuraId = process.env.REACT_APP_INFURA_ID;
const chainId = Number(process.env.REACT_APP_CHAIN_ID) || 56;

const providerOptions = {
  injected: {
    package: null,
  },
  walletconnect: {
    package: WalletConnect,
    options: {
      infuraId,
      rpc: {
        [chainId]: process.env.REACT_APP_RPC,
      },
      chainId,
    },
  },
  coinbasewallet: {
    package: CoinbaseWalletSDK,
    options: {
      infuraId,
    },
  },
  torus: {
    package: TorusConnector,
    options: {
      infuraId
    }
  }
};

const web3Modal = new Web3Modal({
  providerOptions,
  cacheProvider: false,
});