import React, { FC, useContext } from "react";
import Logo from "shared/Logo/Logo";
import Navigation from "shared/Navigation/Navigation";
import SearchDropdown from "./SearchDropdown";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import MenuBar from "shared/MenuBar/MenuBar";
import SwitchDarkMode from "shared/SwitchDarkMode/SwitchDarkMode";
import HeroSearchForm2MobileFactory from "components/HeroSearchForm2Mobile/HeroSearchForm2MobileFactory";
import { AuthContext } from "contexts/AuthContext";
import AvatarDropdown from "./AvatarDropdown";
import ButtonThird from "shared/Button/ButtonThird";
import { BellAlertIcon, BellIcon } from "@heroicons/react/24/outline";
import NotifyDropdown from "./NotifyDropdown";
import LangDropdown from "./LangDropdown";

export interface MainNav1Props {
  className?: string;
}

const MainNav1: FC<MainNav1Props> = ({ className = "" }) => {

  const { isLoggedIn } = useContext(AuthContext);

  // if (!isLoggedIn) return <></>;

  return (
    <div className={`nc-MainNav1 relative z-10 ${className}`}>
      <div className="px-4 lg:container py-4 lg:py-5 relative flex justify-between items-center">
        <div className="hidden md:flex justify-start flex-1 items-center space-x-4 sm:space-x-10">
          <Logo />
          <Navigation />
        </div>

        <div className="lg:hidden justify-between w-full !mx-auto md:px-3 flex items-center">
          {/* <HeroSearchForm2MobileFactory /> */}
          <Logo className="w-28" />
          <Navigation />
          <div className="flex items-center space-x-2">
            <LangDropdown />
            {!isLoggedIn ? 
              <ButtonPrimary className="lg:hidden" href="/signup">Sign up</ButtonPrimary>
              :
              <>
                <NotifyDropdown />
                <AvatarDropdown />
              </>
            }
          </div>
        </div>

        <div className="hidden md:flex flex-shrink-0 items-center justify-end flex-1 lg:flex-none text-neutral-700 dark:text-neutral-100">
          <div className="hidden xl:flex items-center space-x-0.5">
            <LangDropdown />
            {/* <SwitchDarkMode /> */}
            {process.env.REACT_APP_NODE_ENV == "development" ? 
              <>
                { !isLoggedIn ? 
                  <ButtonThird href="/login">Log in</ButtonThird>
                  :
                  <NotifyDropdown />
                }
                <div className="px-1" />
                { !isLoggedIn ? 
                  <ButtonPrimary href="/signup">Sign up</ButtonPrimary>
                  :
                  <AvatarDropdown />
                }
              </> : <></>
            }
          </div>
          <div className="flex xl:hidden items-center">
            <SwitchDarkMode />
            <div className="px-0.5" />
            <MenuBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNav1;
