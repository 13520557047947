import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import React, { FC, useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";
import { Link } from "react-router-dom";
import ButtonSecondary from "shared/Button/ButtonSecondary";

export interface ForgotPassword2Props {
  form: any;
  setDisableContinue(disabled: boolean): void;
  setLoadingContinue(disabled: boolean): void;
  setIsGoBack(disabled: boolean): void;
  setIsContinue(disabled: boolean): void;
}

const ForgotPassword2: FC<ForgotPassword2Props> = ({form, setDisableContinue, setLoadingContinue, setIsGoBack, setIsContinue}) => {

  const [check, setCheck] = useState<boolean>(false);

  useEffect(() => {
    setIsGoBack(false);
    setIsContinue(false);
  }, []);

  return (
    <>
      <div className="space-y-8 flex items-center justify-center h-full px-1">
        <div className="flex flex-col justify-center items-center text-center">
          <CheckCircleIcon className="h-36 w-36 text-green-500" />
          <br/>
          <p className="text-lg">Password recovery instructions sent to <b className="font-bold">{form.email}</b>.</p>
          <br />
          <br />
          <ButtonSecondary href={"/login"} className="w-full md:w-auto">Go to login</ButtonSecondary>
        </div>
      </div>
    </>
  );
};

export default ForgotPassword2;
function setIsContinue(arg0: boolean) {
  throw new Error("Function not implemented.");
}

