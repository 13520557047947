import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import FormItem from "./FormItem";
import { useSnackbar } from "notistack";

export interface ForgotPassword1Props {
  form: any;
  setDisableContinue(disabled: boolean): void;
  setLoadingContinue(disabled: boolean): void;
  setIsGoBack(disabled: boolean): void;
}

const ForgotPassword1: FC<ForgotPassword1Props> = ({form, setDisableContinue, setLoadingContinue, setIsGoBack}) => {

  const [email, setEmail] = useState<any>(form?.email || "");
  const [errors, setErrors] = useState<any>({});
  const { enqueueSnackbar } = useSnackbar();

  const validateEmail = (email: String) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  useEffect(() => {
    setDisableContinue(true);
  }, []);

  useEffect(() => {
    let newErrors = {};
    let error = false;
    if (!email) return;
    if (!validateEmail(email)) {
      if (email.length > 0) {
        newErrors = {...newErrors, email: "You need to enter a valid email."};
        error = true;
      }
    } else {
      newErrors = {...newErrors, email: ""};
    }
    setErrors({...errors, ...newErrors});
    setDisableContinue(error);
  }, [email]);

  return (
    <>
      <h2 className="text-3xl font-semibold">Forgot Password</h2>
      <p className="text-sm">We will send you password recovery instructions to your registered email.</p>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="space-y-8">
        <FormItem
          label="Email"
          desc="Enter the email you registered with us"
          error={errors?.email}
        >
          <Input name="email" type="email" onChange={(e) => setEmail(e.target.value)} placeholder="your@email.com" value={email} />
        </FormItem>
      </div>
    </>
  );
};

export default ForgotPassword1;
