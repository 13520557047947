import Heading from "components/Heading/Heading";
import React, { ReactNode } from "react";
import { useTranslation } from "react-i18next";
import NcImage from "shared/NcImage/NcImage";
import web3SimplerBgLeftImg from "images/web3simpler_bg_left.png";
import web3SimplerBgRightImg from "images/web3simpler_bg_right.png";
import videoArrowsImg from "images/videos_arrows.png";
import marcosplazaImg from "images/marcosplaza.jpg";
import gonzaloscannoneImg from "images/gonzaloscannone.jpg";
import marcosdominguezImg from "images/marcosdominguez.jpg";

export interface People {
  id: string;
  name: string;
  job: string;
  avatar: any;
}

const FOUNDER_DEMO: People[] = [
  {
    id: "1",
    name: `Marcos Plaza de Ayala`,
    job: "Co-founder and CEO",
    avatar: marcosplazaImg,
  },
  {
    id: "4",
    name: `Gonzalo Scannone`,
    job: "Co-founder and CTO",
    avatar: gonzaloscannoneImg,
  },
  {
    id: "3",
    name: `Marcos Dominguez`,
    job: "Co-founder and CMO",
    avatar: marcosdominguezImg,
  },
];

const SectionFounder = () => {

  const { t } = useTranslation();

  return (
    <div className={`nc-SectionGoal -ml-4 -mr-4 md:-ml-24 md:-mr-24 !-mb-24 lg:!-mb-28 bg-[#348282] relative`}> 
      <div className={`space-y-16 p-8 md:px-24 py-24 text-left relative z-10 flex flex-col items-left !md:p-24 text-white`}>
        <img src={videoArrowsImg} className="w-16 -mb-10" />
        <Heading
          desc={t(`about.our_team_desc`)}
          className="text-white w-full"
          classNameDesc="text-neutral-200 max-w-xl"
          isCenter={false}
        >
          {t(`about.our_team`)}
        </Heading>
        <div className="grid gap-x-5 gap-y-8 lg:grid-cols-4 xl:gap-x-8 w-full px-4 md:px-0">
          {FOUNDER_DEMO.map((item) => (
            <div key={item.id} className="max-w-sm">
              <NcImage
                containerClassName="relative h-0 aspect-h-1 aspect-w-1 rounded-xl overflow-hidden"
                className="absolute inset-0 object-cover"
                src={item.avatar}
              />
              <h3 className="text-lg font-semibold text-white mt-4 md:text-xl">
                {item.name}
              </h3>
              <span className="block text-sm text-neutral-200 sm:text-base">
                {item.job}
              </span>
            </div>
          ))}
        </div>
      </div>

      <img src={web3SimplerBgLeftImg} className="absolute left-0 bottom-0 w-[75%] md:w-[25%]" />
      <img src={web3SimplerBgRightImg} className="absolute right-0 top-0 w-[75%] md:w-[25%]" />
    </div>
  );
};

export default SectionFounder;
