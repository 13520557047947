import CardCategoryBox1 from "components/CardCategoryBox1/CardCategoryBox1";
import Heading from "components/Heading/Heading";
import { TaxonomyType } from "data/types";
import React from "react";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import polygonImg from "images/polygon.png";
import zillowImg from "images/zillow.png";
import chainlinkImg from "images/chainlink.png";
import coinbaseImg from "images/coinbase.png";
import metamaskImg from "images/metamask.png";
import ripio2Img from "images/ripio2.png";
import paxosImg from "images/paxos.png";

export interface SectionPartnersProps {
  headingCenter?: boolean;
  categoryCardType?: "card1";
  className?: string;
  gridClassName?: string;
}

const SectionPartners: React.FC<SectionPartnersProps> = ({
  categoryCardType = "card1",
  headingCenter = true,
  className = "",
  gridClassName = "grid-cols-2 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4",
}) => {
  

  const { t } = useTranslation();

  const categories: TaxonomyType[] = [
    {
      id: "1",
      href: "https://polygon.technology/",
      name: "Polygon",
      desc: "Blockchain",
      taxonomy: "category",
      thumbnail: polygonImg,
    },
  {
      id: "2",
      href: "https://zillow.com/",
      name: "Zillow",
      desc: t('home.partners.market_data'),
      taxonomy: "category",
      thumbnail: zillowImg,
    },
    {
      id: "3",
      href: "https://chain.link/",
      name: "Chainlink",
      desc: t('home.partners.oracle'),
      taxonomy: "category",
      thumbnail: chainlinkImg,
    },
    {
      id: "4",
      href: "https://coinbase.com/",
      name: "Coinbase",
      desc: t('home.partners.wallet'),
      taxonomy: "category",
      thumbnail: coinbaseImg,
    },
    {
      id: "5",
      href: "https://metamask.io/",
      name: "Metamask",
      desc: t('home.partners.wallet'),
      taxonomy: "category",
      thumbnail: metamaskImg,
    },
    {
      id: "6",
      href: "https://ripio.com/",
      name: "Ripio",
      desc: t('home.partners.exchange'),
      taxonomy: "category",
      thumbnail: ripio2Img,
    },
    {
      id: "7",
      href: "https://paxos.com/",
      name: "Paxos",
      desc: t('home.partners.exchange'),
      taxonomy: "category",
      thumbnail: paxosImg,
    }
  ];

  let CardComponentName = CardCategoryBox1;
  switch (categoryCardType) {
    case "card1":
      CardComponentName = CardCategoryBox1;
      break;

    default:
      CardComponentName = CardCategoryBox1;
  }

  return (
    <ScrollAnimation animateIn="fadeIn" className={`nc-SectionPartners relative ${className}`}>
      <Heading
        desc={t('home.partners.subtitle')}
        isCenter={headingCenter}
      >
        {t('home.partners.title')}
      </Heading>
      <div className={`grid ${gridClassName} gap-5 sm:gap-6 md:gap-8`}>
        {categories.map((item, i) => (
          <CardComponentName key={i} taxonomy={item} />
        ))}
      </div>
    </ScrollAnimation>
  );
};

export default SectionPartners;
