import React, { FC, ReactNode } from "react";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import imagePng from "images/hero-right.png";
import HeroSearchForm from "components/HeroSearchForm/HeroSearchForm";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { useTranslation } from "react-i18next";
import ScrollAnimation from 'react-animate-on-scroll';
import { MouseParallaxChild, MouseParallaxContainer } from "react-parallax-mouse";
import phoneImg from "images/phone.png";
import phone1Img from "images/phone1.png";
import phone2Img from "images/phone2.png";

export interface SectionHeroProps {
  className?: string;
}

const SectionHero: FC<SectionHeroProps> = ({ className = "" }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`nc-SectionHero flex flex-col-reverse lg:flex-col relative md:!-ml-4 md:!-mr-4 !-mb-6 lg:!-mb-28 ${className}`}
      data-nc-id="SectionHero"
    >
      <div className="flex flex-col lg:flex-row lg:items-start md:mt-5">
        <ScrollAnimation animateIn="fadeIn" className="flex-shrink-0 lg:w-3/5 flex flex-col items-start space-y-8 sm:space-y-10 pb-14 lg:pb-64 xl:pr-14 lg:mt-0 lg:ml-3 lg:mr-10 xl:mr-0">
          <span className="leading-[1.15] md:leading-[1.05] font-extrabold text-transparent text-6xl md:text-[5.4rem] bg-clip-text bg-gradient-to-r from-[#33BCB5] to-[#348282]">
            {t('home.hero.title')} 
          </span>
          <span className="text-neutral-700 text-xl max-w-lg">{t('home.hero.subtitle')}</span>
          <ButtonSecondary>{t('home.hero.button')}</ButtonSecondary>
        </ScrollAnimation>
        <ScrollAnimation animateIn="fadeIn" className="flex-grow md:mt-16 md:pr-10 md:-ml-5">
          <MouseParallaxContainer className="h-full w-[78%] relative !overflow-visible">
            <MouseParallaxChild className="w-full" factorX={0.0} factorY={0.0}>
              <img src={phoneImg} className="w-full float-left ml-10 md:ml-0" />
            </MouseParallaxChild>
            <MouseParallaxChild className="w-full" factorX={0.02} factorY={0.02}>
              <img src={phone2Img} className="w-[70%] md:w-[80%] absolute -left-5 md:-left-44 top-44" />
            </MouseParallaxChild>
            <MouseParallaxChild factorX={-0.02} factorY={-0.02}>
              <img src={phone1Img} className="w-[80%] md:w-full absolute left-40 md:left-44 -top-8 md:-top-10" />
            </MouseParallaxChild>
          </MouseParallaxContainer>
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default SectionHero;
