import React, { FC } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import PropertyCard from "components/PropertyCard/PropertyCard";
import { useTranslation } from "react-i18next";
import ScrollAnimation from "react-animate-on-scroll";
import dashedLineImg from "images/dashed_line.png";

export interface SectionNeverBeenSimplerProps {
  className?: string;
  rightImg?: string;
  type?: "type1" | "type2";
}

const SectionNeverBeenSimpler: FC<SectionNeverBeenSimplerProps> = ({
  className = "lg:py-14",
  rightImg = rightImgPng,
  type = "type1",
}) => {

  const { t } = useTranslation();

  return (
    <div
      className={`nc-SectionNeverBeenSimpler relative flex flex-col items-center lg:flex-row ${className}`}
      data-nc-id="SectionNeverBeenSimpler"
    >
      <ScrollAnimation animateIn="fadeIn" className={`max-w-2xl flex-shrink-0 mt-10 lg:mt-0 lg:w-3/5 lg:pl-28 lg:pr-16 relative`}>
        <img src={dashedLineImg} className="hidden md:inline-block absolute left-4 h-60 top-[40%]" />
        <span className="uppercase text-sm text-gray-400 tracking-widest">
          {t('home.steps.subtitle')}
        </span>
        <h2 className="font-semibold text-4xl mt-5">{t('home.steps.title')}</h2>

        <ul className="space-y-10 mt-16">
          <li className="space-y-3">
            <div className="flex space-x-2 align-center">
              <div>
                <Badge name="01" color="green" />
              </div>
              <span className="block text-xl font-semibold">
                {t('home.steps.item0.title')}
              </span>
            </div>
            <span className="block mt-5 text-sm text-neutral-500 dark:text-neutral-400">
              {t('home.steps.item0.desc')} 
            </span>
          </li>
          <li className="space-y-3">
            <div className="flex space-x-2 align-center">
              <div>
                <Badge name="02" color="blue" />
              </div>
              <span className="block text-xl font-semibold">
                {t('home.steps.item1.title')}
              </span>
            </div>
            <span className="block mt-5 text-sm text-neutral-500 dark:text-neutral-400">
              {t('home.steps.item1.desc')} 
            </span>
          </li>
          <li className="space-y-3">
            <div className="flex space-x-2 align-center">
              <div>
                <Badge name="03" color="pink" />
              </div>
              <span className="block text-xl font-semibold">
                {t('home.steps.item2.title')}
              </span>
            </div>
            <span className="block mt-5 text-sm text-neutral-500 dark:text-neutral-400">
              {t('home.steps.item2.desc')} 
            </span>
          </li>
        </ul>
      </ScrollAnimation>
      <ScrollAnimation animateIn="slideInRight" className="flex w-full pt-14 md:pt-0 lg:pr-10">
        <PropertyCard test={true} />
      </ScrollAnimation>
    </div>
  );
};

export default SectionNeverBeenSimpler;
