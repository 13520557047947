import rightImg from "images/about-hero-right.png";
import React, { FC } from "react";
import SectionFounder from "./SectionFounder";
import SectionStatistic from "./SectionStatistic";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionHero from "./SectionHero";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import { useTranslation } from "react-i18next";
import SectionJoinWaitList from "components/SectionJoinWaitList/SectionJoinWaitList";
import SectionGoal from "./SectionGoal";
import SectionCoreValues from "./SectionCoreValues";
import BgRebloxAbout from "components/BgRebloxAbout/BgRebloxAbout";

export interface PageAboutProps {
  className?: string;
}

const PageAbout: FC<PageAboutProps> = ({ className = "" }) => {

  const { t } = useTranslation();

  return (
    <div
      className={`nc-PageAbout overflow-hidden relative ${className}`}
      data-nc-id="PageAbout"
    >
      <Helmet>
        <title>About || Reblox Real Estate</title>
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />
      <BgRebloxAbout />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        <SectionHero
          rightImg={rightImg}
          heading={`${t(`about.about_us`)}`}
          btnText=""
          subHeading={<>{t(`about.about_us_desc`)}<br/><br/>{t(`about.about_us_desc_2`)}</>}
        />

        <SectionFounder />

        <SectionGoal />

        <SectionCoreValues />

        <SectionJoinWaitList />
      </div>
    </div>
  );
};

export default PageAbout;
