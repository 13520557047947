import React, { FC } from "react";
import GallerySlider from "components/GallerySlider/GallerySlider";
import { DEMO_STAY_LISTINGS } from "data/listings";
import { StayDataType } from "data/types";
import StartRating from "components/StartRating/StartRating";
import { Link } from "react-router-dom";
import BtnLikeIcon from "components/BtnLikeIcon/BtnLikeIcon";
import SaleOffBadge from "components/SaleOffBadge/SaleOffBadge";
import Badge from "shared/Badge/Badge";
import { ArrowDownIcon, ArrowUpIcon } from "@heroicons/react/24/solid";
import { LinearProgress } from "@mui/material";
import { makeStyles } from '@mui/styles';
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonThird from "shared/Button/ButtonThird";
import convertNumbThousand from "utils/convertNumbThousand";
import examplePropertyImg from "images/example_property.jpg";

export interface PropertyCardProps {
  className?: string;
  data?: any;
  size?: "default" | "small";
  test?: boolean;
}

const DEMO_DATA = DEMO_STAY_LISTINGS[0];

const PropertyCard: FC<PropertyCardProps> = ({
  size = "default",
  className = "",
  data = {
    images: [examplePropertyImg],
    slug: "/",
    id: "test",
    address: "1234 Main St.",
    city: "Miami",
    state: "Florida",
    unit_type: "Single Family",
    irr: 14.12,
    investment_term_months: 10,
    toRaise: 322000
  },
  test = false
}) => {
  const {
    images,
    slug,
    id,
    address,
    city,
    state,
    unit_type,
    irr,
    investment_term_months,
    toRaise
  } = data;

  const progressBarStyle = makeStyles({
    colorPrimary: {
      backgroundColor: "#33BCB5"
    },
    barColorPrimary: {
      backgroundColor: '#D1D1D1',
    }
  });

  const renderSliderGallery = () => {
    return (
      <div className="relative w-full">
        <GallerySlider
          uniqueID={`PropertyCard_${id}`}
          ratioClass="aspect-w-4 aspect-h-2 "
          galleryImgs={images}
          href={`/property/${slug}`}
        />
        {/* <BtnLikeIcon isLiked={true} className="absolute right-3 top-3 z-[1]" /> */}
        {<SaleOffBadge desc={"Build-to-sell"} className="absolute left-3 top-3 !bg-primary-400 font-bold !p-4 !px-5" />}
        {/* {<SaleOffBadge desc={<><ArrowDownIcon className="h-3 w-4"/> 5.65%</>} className="absolute left-3 top-3 !bg-red-6000 font-bold" />} */}
      </div>
    );
  };

  const renderContent = () => {
    return (
      <div className="p-7 space-y-5">
        <div className="flex">
          <div className="space-y-1 w-full">
            {/* <span className="text-sm text-neutral-500 dark:text-neutral-400 badge-primary">
              {unit_type}
            </span> */}
            {/* <Badge color="green" name={unit_type} /> */}
            <div className="flex items-center space-x-2">
              {/* {true && <Badge name="ADS" color="green" />} */}
              <h2
                className={` font-bold capitalize ${
                  size === "default" ? "text-lg" : "text-base"
                }`}
              >
                <span className="line-clamp-1">{address}</span>
              </h2>
            </div>
            <div className="flex items-center text-neutral-500 dark:text-neutral-400 text-xs md:space-x-1">
              {size === "default" && (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-4 w-4 hidden md:inline"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M17.657 16.657L13.414 20.9a1.998 1.998 0 01-2.827 0l-4.244-4.243a8 8 0 1111.314 0z"
                  />
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth={1.5}
                    d="M15 11a3 3 0 11-6 0 3 3 0 016 0z"
                  />
                </svg>
              )}
              <span>{city}, {state}</span>
            </div>
          </div>
          <div className="w-full text-primary-400">
            <h2 className="text-xl font-bold text-right mb-0">{irr}% <span className="hidden md:inline">Expected</span> IRR</h2>
            <small className="text-right font-bold block">Term: {investment_term_months} months</small>
          </div>
        </div>
        {/* <div className="w-14 border-b border-neutral-100 dark:border-neutral-800"></div> */}
        <div className="flex justify-between items-end space-x-4">
          <div className="w-full">
            <div className="flex justify-ed !mb-1 text-sm font-semibold">
              <span>10.80% raised</span>
            </div>
            <LinearProgress 
              className="linear-progress" 
              sx={{ height: "0.85rem", borderRadius: "1rem" }} 
              value={10.8} 
              variant="determinate" 
            />
            <div className="flex justify-end text-neutral-500 dark:text-neutral-400 !mt-1 text-xs text-right">
              <span>35,000 / {convertNumbThousand(toRaise)}</span>
            </div>
          </div>
          <div className="w-full flex space-x-2 justify-end">
            <ButtonThird sizeClass="px-4 !py-2 sm:px-6">View Details</ButtonThird>
            {/* <ButtonPrimary sizeClass="px-4 !py-2 sm:px-6">Invest</ButtonPrimary> */}
          </div>
          {/* {!!reviewStart && (
            <StartRating reviewCount={reviewCount} point={reviewStart} />
          )} */}
        </div>
        {/* <div className="text-sm flex bg-primary-6000 text-white font-bold p-3 items-center justify-center -ml-4 -mr-4 -mb-4">
          <span>View property</span>
        </div> */}
      </div>
    );
  };

  return (
    <div
      className={`w-full nc-PropertyCard group relative bg-white dark:bg-neutral-900 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform shadow-xl hover:shadow-3xl transition-shadow ${className}`}
      data-nc-id="PropertyCard"
    >
      {renderSliderGallery()}
      {test ?
        <span>{renderContent()}</span>
        :
        <Link to={`/property/${slug}`}>{renderContent()}</Link>
      }
    </div>
  );
};

export default PropertyCard;
