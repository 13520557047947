import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useContext, useEffect, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import { AuthContext } from "contexts/AuthContext";
import Logo from "shared/Logo/Logo";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { ArrowDownIcon, ArrowUpIcon, DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import NcModal from "shared/NcModal/NcModal";
import ButtonThird from "shared/Button/ButtonThird";
import PirvateKeyModal from "./PrivateKeyModal";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import convertNumbThousand from "utils/convertNumbThousand";
// @ts-ignore
import { getTokenBalance } from "reblox-web3-utils2";
import BigNumber from "bignumber.js";

export interface PropertyProps {
  data?: any;
}

const Property: FC<PropertyProps> = ({ data = {} }) => {

  const { user } = useContext(AuthContext);
  const wallet = user?.wallets[0];

  const [ isLoading, setIsLoading ] = useState<boolean>(true);
  const [ amount, setAmount ] = useState<number>();
  const [ amountUSD, setAmountUSD ] = useState<number>();

  useEffect(() => {
    setIsLoading(true);
    Promise.all([
      getTokenBalance(parseInt(process.env.REACT_APP_CHAIN_ID), wallet?.address, data.token_address)
    ]).then(async ([balance]) => {
      const _amount = new BigNumber(balance).shiftedBy(-data.decimals).toNumber();
      setAmount(_amount);
      setAmountUSD(_amount * data.token_price);
    }).finally(() => {
      setIsLoading(false);
    });
  }, [user]);
  
  return (
    <div
      className={`nc-CardCategoryBox1 relative flex items-center p-3 sm:p-6 [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ] flex-grow justify-between`}
      data-nc-id="CardCategoryBox1"
    >
      <div className="flex items-center">
        <div className="relative flex-shrink-0 w-12 h-12 md:w-16 md:h-16 overflow-hidden rounded-xl">
          <NcImage src={data.images[0]} containerClassName="absolute inset-0" />
        </div>
        <div className="ml-4 flex-grow overflow-hidden">
          <h2 className="text-base font-medium">
            <span className="line-clamp-1">{data.address}</span>
          </h2>
          <span className={`block mt-0 text-sm text-neutral-500 dark:text-neutral-400`}>
            {data.city}, {data.state}
          </span>
        </div>
      </div>
      <div className="overflow-hidden text-right">
        <h2 className="font-semibold text-right">
          {isLoading ? <i className="las la-circle-notch la-spin"></i> : `$${convertNumbThousand(amountUSD)}`}
        </h2>
        <span className={`block mt-0 text-sm text-neutral-500 dark:text-neutral-400 text-right`}>
          {isLoading ? <i className="las la-circle-notch la-spin"></i> : convertNumbThousand(amount)}
        </span>
      </div>
    </div>
  );
};

export default Property;
