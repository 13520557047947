import { Tab } from "@headlessui/react";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import React, { FC, Fragment, useState } from "react";
import visaPng from "images/vis.png";
import mastercardPng from "images/mastercard.svg";
import Input from "shared/Input/Input";
import Label from "components/Label/Label";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import NcImage from "shared/NcImage/NcImage";
import StartRating from "components/StartRating/StartRating";
import NcModal from "shared/NcModal/NcModal";
import ModalSelectDate from "components/ModalSelectDate";
import moment from "moment";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import converSelectedDateToString from "utils/converSelectedDateToString";
import ModalSelectGuests from "components/ModalSelectGuests";
import { GuestsObject } from "components/HeroSearchForm2Mobile/GuestsInput";
import Page404 from "containers/Page404/Page404";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CheckOutPageProps {
  className?: string;
  data?: any;
}

const CheckOutPage: FC<CheckOutPageProps> = ({ className = "", data = {} }) => {

  const [ amount, setAmount ] = useState<number>(100);

  if (!localStorage.getItem('checkout-property')) return <Page404 />
  
  const {
    images,
    slug,
    id,
    address,
    city,
    state,
    unit_type,
    irr,
    investment_term_months,
    token_price
  } = JSON.parse(localStorage.getItem('checkout-property') as string);

  const available_tokens = 50;
  const max_amount = available_tokens * token_price;

  const preSetAmount = (_amount:number) => {
    if (_amount > max_amount) {
      _amount = max_amount;
    }
    if (_amount < 0) {
      _amount = 0;
    }
    setAmount(_amount);
  }

  const renderPropertyDetails = () => {
    return (
      <div className="flex flex-col-reverse sm:flex-row sm:items-center">
        <div className="flex-shrink-0 w-full sm:w-40">
          <div className="aspect-w-3 sm:aspect-w-4 aspect-h-2 sm:aspect-h-4 rounded-2xl overflow-hidden">
            <NcImage src={images[0]} />
          </div>
        </div>
        <div className="flex flex-row sm:flex-col py-3 sm:px-5 sm:py-5 sm:space-y-3 justify-between">
          <div>
            <span className="text-sm text-neutral-500 dark:text-neutral-400 line-clamp-1">
              {city}, {state}
            </span>
            <span className="text-base font-medium mt-1 block text-ellipsis overflow-hidden">
              {address}
            </span>
          </div>
          <div className="">
            <span className="block text-sm text-neutral-500 dark:text-neutral-400 mb-1 sm:mb-0">
              Term: {investment_term_months} months
            </span>
            <div className="hidden sm:block w-10 border-b border-neutral-200 dark:border-neutral-700 mt-3 mb-3"></div>
            <span className="font-bold text-lg sm:text-2xl text-primary-400">{irr}% IRR</span>
          </div>
        </div>
      </div>
    )
  }

  const renderInvestmentSummary = () => {
    return (
      <div className="flex flex-col space-y-4 p-4 rounded-xl shadow overflow-hidden">
        <div className="bg-primary-6000 -mt-4 -ml-4 -mr-4 flex items-center p-4">
          <h3 className="text-xl font-semibold text-white">Investment summary</h3>
        </div>
        <div className="flex justify-between text-neutral-500 dark:text-neutral-400">
          <span>Total amount</span>
          <span>${convertNumbThousand(amount)}</span>
        </div>
        <div className="flex justify-between text-neutral-500 dark:text-neutral-400">
          <span>You will get</span>
          <span>{convertNumbThousand(amount / token_price)} tokens</span>
        </div>

        <div className="border-b border-neutral-100 dark:border-neutral-700"></div>

        <div className="flex justify-between text-neutral-500 dark:text-neutral-400">
          <span>Earnings</span>
          <span>${amount ? convertNumbThousand(amount * irr / 100) : 0}</span>
        </div>
        <div className="flex justify-between font-semibold">
          <span>Expected return</span>
          <span>${amount ? convertNumbThousand(amount + amount * irr / 100) : 0}</span>
        </div>
      </div>
    )
  }

  const renderSidebar = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl lg:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        {renderPropertyDetails()}
        {renderInvestmentSummary()}
      </div>
    );
  };

  const renderMain = () => {
    return (
      <div className="w-full flex flex-col sm:rounded-2xl sm:border border-neutral-200 dark:border-neutral-700 space-y-6 sm:space-y-8 px-0 sm:p-6 xl:p-8">
        <h2 className="text-3xl lg:text-4xl font-semibold">
          Investment
        </h2>
        <div className="block lg:hidden">
          {renderPropertyDetails()}
        </div>
        
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        
        <div>
          <div>
            <h3 className="text-2xl font-semibold">Amount</h3>
            <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>
            <div className="space-y-1">
              <Label className="text-base">Enter amount in USD to invest</Label>
              <Input className="!text-lg !py-6" defaultValue="100" max={max_amount} type="number" value={amount} onChange={(e) => preSetAmount(parseFloat(e.target.value))} />
            </div>
            <div className="flex justify-between -mt-1">
              <div className="flex justify-start text-neutral-6000 dark:text-neutral-300 mt-3 text-sm space-x-1">
                <span>Price:</span>
                <span>${parseFloat(String(token_price)).toFixed(2)} / token</span>
              </div>
              <div className="flex justify-start text-neutral-6000 dark:text-neutral-300 mt-3 text-sm space-x-1">
                <span>Available tokens:</span>
                <span>{available_tokens} tokens</span>
              </div>
            </div>
          </div>
        </div>
        
        <div className="border-b border-neutral-200 dark:border-neutral-700"></div>
        
        <div className="block lg:hidden !mt-10">
          {renderInvestmentSummary()}
        </div>

        {/* <div>
          <h3 className="text-2xl font-semibold">Pay with</h3>
          <div className="w-14 border-b border-neutral-200 dark:border-neutral-700 my-5"></div>

          <div className="mt-6">
            <Tab.Group>
              <Tab.List className="flex my-5">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5 rounded-full focus:outline-none ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                          : "text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      Paypal
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`px-4 py-1.5 sm:px-6 sm:py-2.5  rounded-full flex items-center justify-center focus:outline-none  ${
                        selected
                          ? "bg-neutral-800 dark:bg-neutral-300 text-white dark:text-neutral-900"
                          : " text-neutral-6000 dark:text-neutral-400"
                      }`}
                    >
                      <span className="mr-2.5">Credit card</span>
                      <img className="w-8" src={visaPng} alt="" />
                      <img className="w-8" src={mastercardPng} alt="" />
                    </button>
                  )}
                </Tab>
              </Tab.List>

              <Tab.Panels>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Card number </Label>
                    <Input defaultValue="111 112 222 999" />
                  </div>
                  <div className="space-y-1">
                    <Label>Card holder </Label>
                    <Input defaultValue="JOHN DOE" />
                  </div>
                  <div className="flex space-x-5  ">
                    <div className="flex-1 space-y-1">
                      <Label>Expiration date </Label>
                      <Input type="date" defaultValue="MM/YY" />
                    </div>
                    <div className="flex-1 space-y-1">
                      <Label>CVC </Label>
                      <Input />
                    </div>
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-5">
                  <div className="space-y-1">
                    <Label>Email </Label>
                    <Input type="email" defaultValue="example@gmail.com" />
                  </div>
                  <div className="space-y-1">
                    <Label>Password </Label>
                    <Input type="password" defaultValue="***" />
                  </div>
                  <div className="space-y-1">
                    <Label>Messager for author </Label>
                    <Textarea placeholder="..." />
                    <span className="text-sm text-neutral-500 block">
                      Write a few sentences about yourself.
                    </span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
            <div className="pt-8">
              <ButtonPrimary href={"/pay-done"}>Confirm and pay</ButtonPrimary>
            </div>
          </div>
        </div> */}
      </div>
    );
  };

  return (
    <div className={`nc-CheckOutPage ${className}`} data-nc-id="CheckOutPage">
      <main className="container mt-11 mb-24 lg:mb-32 flex flex-col-reverse lg:flex-row">
        <div className="w-full lg:w-3/5 xl:w-2/3 lg:pr-10 ">{renderMain()}</div>
        <div className="hidden lg:block flex-grow">{renderSidebar()}</div>
      </main>
    </div>
  );
};

export default CheckOutPage;
