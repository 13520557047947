import React, { FC, useEffect, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./PageCreateAccount";
import FormItem from "./FormItem";

export interface CreateAccount3Props {
  form: any;
  setDisableContinue(disabled: boolean): void;
  setLoadingContinue(disabled: boolean): void;
  setIsGoBack(disabled: boolean): void;
}

const CreateAccount3: FC<CreateAccount3Props> = ({form, setDisableContinue, setLoadingContinue, setIsGoBack}) => {

  const [firstName, setFirstName] = useState<any>(form?.first_name);
  const [lastName, setLastName] = useState<any>(form?.last_name);

  useEffect(() => {
    setIsGoBack(false);
  }, [])

  useEffect(() => {
    if (!firstName || !lastName) {
      setDisableContinue(true);
      return;
    }
    setDisableContinue(false);
  }, [firstName, lastName])

  return (
    <>
      <h2 className="text-2xl font-semibold">Now tell us about yourself</h2>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="space-y-8">
        <FormItem
          label="First Name *"
          desc="Your first name as it appears on your government-issued ID."
        >
          <Input name="first_name" type="text" onChange={(e) => setFirstName(e.target.value)} placeholder="John" value={firstName} />
        </FormItem>
        <FormItem
          label="Last Name *"
          desc="Your last name as it appears on your government-issued ID."
        >
          <Input name="last_name" type="text" onChange={(e) => setLastName(e.target.value)} placeholder="Smith" value={lastName} />
        </FormItem>
      </div>
    </>
  );
};

export default CreateAccount3;
