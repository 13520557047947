import Heading from "components/Heading/Heading";
import React, { FC } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Badge from "shared/Badge/Badge";
import { useTranslation } from "react-i18next";
import { MouseParallaxContainer, MouseParallaxChild } from "react-parallax-mouse";
import ScrollAnimation from "react-animate-on-scroll";
import payments0Img from "images/payments0.png";
import payments1Img from "images/payments1.png";
import payments2Img from "images/payments2.png";
import paymentsCaretImg from "images/payments_2.png";

export interface SectionPaymentsProps {
  className?: string;
}

const SectionPayments: FC<SectionPaymentsProps> = ({
  className = "",
}) => {

  const { t } = useTranslation();

  return (
    <div className={`nc-SectionPayments -ml-4 -mr-4 md:-ml-24 md:-mr-24 !-mb-24 lg:!-mb-28 relative bg-[#1C1C1C]`}> 
      <div className="grid grid-cols-1 md:grid-cols-2 relative z-10">
        <div className="w-full h-full flex flex-col justify-center !pb-32 py-16 p-8 md:p-24 space-y-8 relative">
          <div className="space-y-4">
            <h2 className="text-white font-bold text-2xl md:text-3xl">{t('home.simple_access.title')} <span className="text-primary-400">Web3</span></h2>
            <span className="uppercase text-sm text-neutral-400 tracking-widest block">{t('home.simple_access.subtitle')}</span>
          </div>
          <ScrollAnimation animateIn="slideInLeft" className="w-full h-full">
            <MouseParallaxContainer className="h-full w-[80%] relative !overflow-visible">
              <MouseParallaxChild className="w-full" factorX={0.0} factorY={0.0}>
                <img src={payments0Img} className="w-full float-left" />
              </MouseParallaxChild>
              <MouseParallaxChild className="w-full" factorX={-0.05} factorY={0.05}>
                <img src={payments1Img} className="w-full absolute" />
              </MouseParallaxChild>
              <MouseParallaxChild factorX={0.12} factorY={-0.12}>
                <img src={payments2Img} className="w-full absolute left-24 top-4" />
              </MouseParallaxChild>
            </MouseParallaxContainer>
          </ScrollAnimation>
          <ScrollAnimation animateIn="slideInRight" className="absolute w-8 md:w-20 bottom-20 right-7 md:bottom-20 right-16">
            <img src={paymentsCaretImg} className="w-full h-full" />
          </ScrollAnimation>
        </div>
        <div className="w-full h-full bg-[#262626] px-4 py-12 md:p-16 space-y-8 flex flex-col justify-center">
          <ScrollAnimation animateIn="fadeIn" className="space-y-12 p-8 md:p-12 block">

            <div className="space-y-4">
              <Badge color="green" name={t('home.simple_access.item0.title')} />
              <p className="text-neutral-300 text-base">
                {t('home.simple_access.item0.desc')}
              </p>
            </div>
            
            <div className="space-y-4">
              <Badge color="blue" name={t('home.simple_access.item1.title')} />
              <p className="text-neutral-300 text-base">
                {t('home.simple_access.item1.desc')}
              </p>
            </div>
            
            <div className="space-y-4">
              <Badge color="pink" name={t('home.simple_access.item2.title')} />
              <p className="text-neutral-300 text-base">
                {t('home.simple_access.item2.desc')}
              </p>
            </div>
          </ScrollAnimation>
        </div>
      </div>
    </div>
  );
};

export default SectionPayments;
