import React, { FC } from "react";
import isologo from "images/isologo.png";

export interface BgRebloxAboutProps {
  className?: string;
}

const BgRebloxAbout: FC<BgRebloxAboutProps> = ({
  className = "absolute inset-x-0 min-h-0 flex overflow-hidden z-0 w-full justify-between",
}) => {
  return (
    <div
      className={`nc-BgRebloxAbout ${className}`}
      data-nc-id="BgRebloxAbout"
    >
      <div className="w-full hidden md:inline-block ">
        <img src={isologo} className="opacity-10 mt-24 -ml-[10%] w-[70%]" />
      </div>
      <div className="w-full text-right">
        <img src={isologo} className="opacity-10 ml-[45%] -mt-4 w-[70%]" />
      </div>
    </div>
  );
};

export default BgRebloxAbout;
