import React, { FC, useState } from "react";
import { Helmet } from "react-helmet";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import SocialsList from "shared/SocialsList/SocialsList";
import Label from "components/Label/Label";
import Input from "shared/Input/Input";
import Textarea from "shared/Textarea/Textarea";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import SectionClientSay from "components/SectionClientSay/SectionClientSay";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionJoinWaitList from "components/SectionJoinWaitList/SectionJoinWaitList";
import { useSnackbar } from "notistack";
import { useTranslation } from "react-i18next";
import payments2 from "images/payments_2.png";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import SocialsList1 from "shared/SocialsList1/SocialsList1";

export interface PageContactProps {
  className?: string;
}

const PageContact: FC<PageContactProps> = ({ className = "" }) => {

  const [name, setName] = useState(""); 
  const [email, setEmail] = useState(""); 
  const [message, setMessage] = useState(""); 
  const [isSending, setIsSending] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();
  const { t } = useTranslation();

  const encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&');
  };

  const info = [
    {
      title: `📍 ${t('contact.address')}`,
      desc: "Buenos Aires, Argentina - Miami, USA",
    },
    {
      title: `📧 ${t('contact.email')}`,
      desc: <a target="_blank" href="mailto:business@reblox.ai">business@reblox.ai</a>,
    },
  ];

  const send = () => {
    // Send to netlify
    if (email && name && message) {
      setIsSending(true);
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 
          'form-name': "contact", 
          'name': name,
          'email': email,
          'message': message,
        })
      }).finally(() => {
        enqueueSnackbar(t("contact.form.success"), { variant: "success" });
        setName("");
        setEmail("");
        setMessage("");
        setIsSending(false);
      });
    }
  }

  return (
    <div
      className={`nc-PageContact overflow-hidden relative ${className}`}
      data-nc-id="PageContact"
    >
      <BgGlassmorphism />
      <Helmet>
        <title>Contact || Booking React Template</title>
      </Helmet>
      <div className="mb-24 lg:mb-32 relative z-10">
        <h2 className="my-16 sm:my-20 flex items-center text-3xl leading-[115%] md:text-5xl md:leading-[115%] font-semibold text-neutral-900 dark:text-neutral-100 justify-center">
          {t('contact.contact')}
        </h2>
        <div className="container max-w-7xl mx-auto">
          <div className="flex-shrink-0 flex flex-col-reverse md:grid grid-cols-1 md:grid-cols-2 gap-12 ">
            <div className="max-w-sm space-y-8">
              {info.map((item, index) => (
                <div key={index}>
                  <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                    {item.title}
                  </h3>
                  <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
                    {item.desc}
                  </span>
                </div>
              ))}
              <div>
                <h3 className="uppercase font-semibold text-sm dark:text-neutral-200 tracking-wider">
                  🌏 {t('contact.socials')}
                </h3>
                <SocialsList1 className="mt-2 flex flex-col items-start" itemClassName="!text-neutral-500 !dark:text-neutral-400" />
              </div>
              <div>
                <img src={payments2} className="w-20 mt-24" style={{ transform: "rotateY(180deg)" }} />
              </div>
            </div>
            <div>
              <form className="grid grid-cols-1 gap-6" action="#" method="post">
                <label className="block">
                  <Label>{t('contact.form.name')}</Label>
                  <Input
                    placeholder="John Doe"
                    type="text"
                    className="mt-1" 
                    onChange={(e) => setName(e.target.value)}
                    value={name}
                  />
                </label>
                <label className="block">
                  <Label>{t('contact.form.email')}</Label>

                  <Input
                    type="email"
                    placeholder="your@email.com"
                    className="mt-1" 
                    onChange={(e) => setEmail(e.target.value)}
                    value={email}
                  />
                </label>
                <label className="block">
                  <Label>{t('contact.form.message')}</Label>
                  <Textarea 
                    className="mt-1" 
                    rows={6} 
                    onChange={(e) => setMessage(e.target.value)} 
                  />
                </label>
                <div>
                  <ButtonPrimary loading={isSending} onClick={() => send()} type="button">{t('contact.form.send_message')}</ButtonPrimary>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
      
      <div className="container -mt-10 lg:py-28 space-y-16 lg:space-y-28">
        <SectionJoinWaitList />
      </div>
    </div>
  );
};

export default PageContact;
