import React, { FC, useState, Fragment, useEffect } from "react";
import rightImgPng from "images/our-features.png";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import PropertyCard from "components/PropertyCard/PropertyCard";
import FormItem from "containers/PageCreateAccount/FormItem";
import Input from "shared/Input/Input";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import { Tab } from "@headlessui/react";
import convertNumbThousand from "utils/convertNumbThousand";
import { useTranslation } from "react-i18next";
import ScrollAnimation from 'react-animate-on-scroll';
import featuresBgImg from "images/features_bg.png";
import simulateInvestmentBgImg from "images/simulate_investment_bg.png";
import simulateInvestmentImg from "images/simulate_investment.png";

export interface SectionSimulateInvestmentProps {
  className?: string;
}

const SectionSimulateInvestment: FC<SectionSimulateInvestmentProps> = ({
  className = "lg:py-14",
}) => {

  const [investmentAmount, setInvestmentAmount] = useState<number>(1000);
  const [showResult, setShowResult] = useState<boolean>(true);
  const [monthlyData, setMonthlyData] = useState<any>();
  const [finalRentData, setFinalRentData] = useState<any>();

  const { t } = useTranslation();

  useEffect(() => {
    if (isNaN(investmentAmount)) {
      setInvestmentAmount(0);
    }
  }, [investmentAmount])

  useEffect(() => {
    setMonthlyData({
      irr: 8.9
    });
    setFinalRentData({
      irr: 13.9,
      investment_term_months: 12
    });
  }, []);

  return (
    <div
      className={`nc-SectionSimulateInvestment relative flex flex-col-reverse items-center lg:flex-row ${className}`}
      data-nc-id="SectionSimulateInvestment"
    >
      <img src={featuresBgImg} className="hidden md:inline-block absolute -left-24 w-[30%] z-0 -top-10" />
      <ScrollAnimation animateIn="slideInLeft" className="hidden md:inline-block absolute -left-24 w-[50%] z-0 bottom-0">
        <img src={simulateInvestmentBgImg} />
      </ScrollAnimation>
      <div className="w-full md:w-[45%] md:ml-12">
        <ScrollAnimation animateIn="fadeIn" className="w-full space-y-8 p-8 md:p-12 bg-white dark:bg-neutral-900 dark:border-neutral-800 rounded-2xl overflow-hidden will-change-transform shadow-xl hover:shadow-3xl transition-shadow text-center">
          <h2 className="font-semibold text-2xl md:text-3xl">{t('home.simulate.title')}</h2>
          <p className="text-neutral-500 text-sm">{t('home.simulate.subtitle')}</p>
          <FormItem
            label={t('home.simulate.invest')}
            desc="."
            className="text-left max-w-xs mx-auto"
            suffix={<span className="text-neutral-400 text-sm text-semibold">USD</span>}
          >
            <Input type="text" onChange={(e) => setInvestmentAmount(parseFloat(e.target.value))} placeholder="1000" value={investmentAmount} />
          </FormItem>
          {/* <ButtonSecondary className="hidden" onClick={() => calculateProfit()}>Calculate Profit</ButtonSecondary> */}
          {showResult && (
            <Tab.Group>
              <Tab.List className="!mt-2 flex overflow-x-auto hiddenScrollbar bg-neutral-200 p-2 rounded-full space-x-2 w-fit mx-auto">
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-white text-neutral-800 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {t('home.simulate.final_rent')}
                    </button>
                  )}
                </Tab>
                <Tab as={Fragment}>
                  {({ selected }) => (
                    <button
                      className={`flex-shrink-0 block !leading-none font-medium px-5 py-2.5 text-sm sm:px-6 sm:py-3 capitalize rounded-full focus:outline-none ${
                        selected
                          ? "bg-white text-neutral-800 "
                          : "text-neutral-500 dark:text-neutral-400 dark:hover:text-neutral-100 hover:text-neutral-900 hover:bg-neutral-100 dark:hover:bg-neutral-800"
                      } `}
                    >
                      {t('home.simulate.monthly')}
                    </button>
                  )}
                </Tab>
              </Tab.List>
              <Tab.Panels className="px-4 md:px-6 py-0">
                <Tab.Panel className="space-y-4">
                  <div className="flex justify-between items-center text-sm text-neutral-500">
                    <span>{t('home.simulate.expected_irr')}</span>
                    <span className="text-right">{finalRentData?.irr}%</span>
                  </div>
                  <div className="flex justify-between items-center text-sm text-neutral-500">
                    <span>{t('home.simulate.investment_term')}</span>
                    <span className="text-right">{finalRentData?.investment_term_months} {t('home.simulate.months')}</span>
                  </div>
                  <div className="flex justify-between items-center text-sm text-neutral-500">
                    <span>{t('home.simulate.earnings')}</span>
                    <span className="text-right font-bold text-black">${convertNumbThousand(finalRentData?.irr / 100 * investmentAmount)}</span>
                  </div>
                  <div className="flex justify-between items-center text-sm text-neutral-500">
                    <span>{t('home.simulate.total')}</span>
                    <span className="text-right font-bold text-black">${convertNumbThousand(finalRentData?.irr / 100 * investmentAmount + investmentAmount)}</span>
                  </div>
                </Tab.Panel>
                <Tab.Panel className="space-y-4">
                  <div className="flex justify-between items-center text-sm text-neutral-500">
                    <span>{t('home.simulate.expected_apr')}</span>
                    <span className="text-right">{convertNumbThousand(monthlyData?.irr)}%</span>
                  </div>
                  <div className="flex justify-between items-center text-sm text-neutral-500">
                    <span>{t('home.simulate.annual_earnings')}</span>
                    <span className="text-right font-bold text-black">${convertNumbThousand(monthlyData?.irr / 100 * investmentAmount)}</span>
                  </div>
                  <div className="flex justify-between items-center text-sm text-neutral-500">
                    <span>{t('home.simulate.monthly_earnings')}</span>
                    <span className="text-right font-bold text-black">${convertNumbThousand(monthlyData?.irr / 100 * investmentAmount / 12)}</span>
                  </div>
                </Tab.Panel>
              </Tab.Panels>
            </Tab.Group>
          )}
        </ScrollAnimation>
      </div>
      <div className="w-full md:w-[55%] md:-ml-12 transition-all md:mt-16">
        <ScrollAnimation animateIn="slideInUp">
          <img src={simulateInvestmentImg} className="relative z-10 transition-all" />
        </ScrollAnimation>
      </div>
    </div>
  );
};

export default SectionSimulateInvestment;
