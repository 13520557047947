import { Popover, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/solid";
import { GlobeAltIcon } from "@heroicons/react/24/outline";
import { FC, Fragment, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import enImg from "images/flags/en.png";
import esImg from "images/flags/es.png";
import brImg from "images/flags/br.png";

export const headerLanguage = [
  {
    id: "en",
    name: "English",
  },
  {
    id: "es",
    name: "Español",
  },
  // {
  //   id: "br",
  //   name: "Portugues",
  // },
];

interface LangDropdownProps {
  panelClassName?: string;
}

const LangDropdown: FC<LangDropdownProps> = ({
  panelClassName = "z-10 px-2 mt-4 right-0 sm:px-0 mr-0 md:mr-2",
}) => {

  const { i18n } = useTranslation();
  const [lang, setLang] = useState<string>(localStorage.getItem('i18nextLng') || (navigator.language).substring(0,2) || "en");
  const images = {
    "en": enImg,
    "es": esImg,
    "br": brImg,
  }

  useEffect(() => {
    i18n.changeLanguage(lang);
    localStorage.setItem('i18nextLng', lang);
  }, [lang])

  return (
    <div className="LangDropdown">
      <Popover className="relative">
        {({ open, close }) => (
          <>
            <Popover.Button
              className={`
                ${open ? "" : "text-opacity-80"}
             group px-1 md:px-3 py-1.5 border-neutral-300 hover:border-neutral-400 dark:border-neutral-700 rounded-full inline-flex items-center text-sm text-gray-700 dark:text-neutral-300 font-medium hover:text-opacity-100 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75`}
            >
              <img className="h-4 w-6 md:h-5 md:w-7 rounded-sm" src={images[i18n.language.toLowerCase()]} />
              <ChevronDownIcon
                className={`${open ? "-rotate-180" : "text-opacity-70"}
                  ml-1 h-4 w-4  group-hover:text-opacity-80 transition ease-in-out duration-150`}
                aria-hidden="true"
              />
            </Popover.Button>
            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className={`absolute min-w-[75px] md:min-w-[50px] md:min-w-auto ${panelClassName}`}>
                <div className="overflow-hidden rounded-2xl shadow-lg ring-1 ring-black ring-opacity-5">
                  <div className="relative grid gap-8 bg-white dark:bg-neutral-800 p-4 lg:grid-cols-1">
                    {headerLanguage.map((item, index) => (
                      <span
                        key={index}
                        onClick={() => {close(); setLang(item.id) }}
                        className={`cursor-pointer flex items-center p-2 -m-3 transition duration-150 ease-in-out rounded-lg hover:bg-neutral-100 dark:hover:bg-neutral-700 focus:outline-none focus-visible:ring focus-visible:ring-orange-500 focus-visible:ring-opacity-50 ${
                          item.id == lang
                            ? "bg-gray-100 dark:bg-neutral-700"
                            : "opacity-80"
                        }`}
                      >
                        <img className="h-5 w-8 rounded-sm" src={images[item.id.toLowerCase()]} />
                      </span>
                    ))}
                  </div>
                </div>
              </Popover.Panel>
            </Transition>
          </>
        )}
      </Popover>
    </div>
  );
};
export default LangDropdown;
