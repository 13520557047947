import React, { FC } from "react";
import { TaxonomyType } from "data/types";
import { Link } from "react-router-dom";
import NcImage from "shared/NcImage/NcImage";
import Badge from "shared/Badge/Badge";
import convertNumbThousand from "utils/convertNumbThousand";

export interface CardCategoryBox1Props {
  className?: string;
  taxonomy: TaxonomyType;
}

const CardCategoryBox1: FC<CardCategoryBox1Props> = ({
  className = "",
  taxonomy,
}) => {
  const { count, name, thumbnail, href = "/", desc } = taxonomy;
  return (
    <a
      href={href}
      target="_blank"
      className={`nc-CardCategoryBox1 relative flex flex-col md:flex-row items-center p-3 sm:p-6 space-y-3 md:space-y-0  [ nc-box-has-hover ] [ nc-dark-box-bg-has-hover ]  ${className}`}
      data-nc-id="CardCategoryBox1"
    >
      {/* <Badge
        className="absolute right-2 top-2"
        color="gray"
        name={convertNumbThousand(count)}
      /> */}

      <div className="relative flex-shrink-0 w-20 h-20 md:w-24 md:h-24 rounded-xl overflow-hidden">
        <NcImage src={thumbnail} containerClassName="absolute inset-0" />
      </div>
      <div className="md:ml-4 flex-grow overflow-hidden">
        <h2 className="text-sm md:text-base text-center md:text-left font-medium">
          <span className="line-clamp-1">{name}</span>
        </h2>
        <span
          className={`block md:mt-2 text-sm text-center md:text-left text-neutral-500 dark:text-neutral-400`}
        >
          {desc}
        </span>
      </div>
    </a>
  );
};

export default CardCategoryBox1;
