import React, { FC, LegacyRef, useEffect, useRef, useState } from "react";
import Input from "shared/Input/Input";
import Select from "shared/Select/Select";
import CommonLayout from "./PageCreateAccount";
import FormItem from "./FormItem";
import ReactCodeInput from "react-code-input";
import { useSnackbar } from "notistack";

export interface CreateAccount2Props {
  form: any;
  setDisableContinue(disabled: boolean): void;
  setLoadingContinue(disabled: boolean): void;
  setIsGoBack(disabled: boolean): void;
}

const CreateAccount2: FC<CreateAccount2Props> = ({form, setDisableContinue, setLoadingContinue, setIsGoBack}) => {

  const codeRef = useRef<any>(undefined);
  const [code, setCode] = useState<string>(form?.code || "");
  const [codeOK, setCodeOK] = useState<boolean>(false);
  const [emailSent, setEmailSent] = useState<boolean>(false);
  const [lastSentTimestamp, setLastSentTimestamp] = useState<number>(parseInt(localStorage.getItem('lastSentTimestamp') as string) || 0);
  const sendAgainDelay = 60 * 1000;
  const [actualTimestamp, setActualTimestamp] = useState<number>(new Date().getTime());
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (code.length != 4 || codeOK == false) {
      setDisableContinue(true);
      return;
    }
    setDisableContinue(false);
  }, [code, codeOK]);
  
  const sendEmail = async () => {
    if (lastSentTimestamp + sendAgainDelay > actualTimestamp) return;
    setLastSentTimestamp(actualTimestamp);
    localStorage.setItem('lastSentTimestamp', String(actualTimestamp));
    fetch(`${process.env.REACT_APP_API_URL}/users/send_email_code`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        email: form?.email,
      }),
    }).then((response) => {
      enqueueSnackbar("Verification email sent", { variant: "success" });
      setEmailSent(true);
    });
  }
  
  const checkCode = async (newCode: string) => {
    setCode(newCode);
    if (newCode.length == 4) {
      setLoadingContinue(true);
      fetch(`${process.env.REACT_APP_API_URL}/users/check_email_code`, {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        credentials: "include",
        body: JSON.stringify({
          code: newCode,
        }),
      }).then((response) => {
        return response.json();
      }).then((json) => {
        if (json?.success == false) {
          codeRef.current.state.input[0] = "";
          codeRef.current.state.input[1] = "";
          codeRef.current.state.input[2] = "";
          codeRef.current.state.input[3] = "";
          codeRef.current.textInput[0].focus();
          setCode("");
          enqueueSnackbar("Wrong code. Please try again", { variant: "error" });
          setCodeOK(false);
        } else {
          setCodeOK(true);
        }
      }).finally(() => {
        setLoadingContinue(false);
      });
    }
  }

  useEffect(() => {
    if (emailSent == false) {
      sendEmail();
    }
    const interval = setInterval(() => {
      setActualTimestamp(new Date().getTime());
    }, 1000);
    return () => {
      clearInterval(interval)  
    }
   }, []);

  return (
    <>
      <h2 className="text-2xl font-semibold">Check your inbox</h2>
      <p className="text-base">We sent an email to <b>{form.email}</b> with a 4-digit code. Check your junk or spam folders, it might be in there.</p>
      <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
      <div className="space-y-8">
        <FormItem 
          label="Code"
          desc={
            <>
              Didn't get the code?<span className={`${lastSentTimestamp + sendAgainDelay > actualTimestamp ? "text-neutral-500" : "text-primary-500 cursor-pointer font-bold"} `} onClick={() => {lastSentTimestamp + sendAgainDelay > actualTimestamp ? void(0) : sendEmail()}}> Send again {lastSentTimestamp + sendAgainDelay > actualTimestamp ? ` in ${(((lastSentTimestamp + sendAgainDelay) - actualTimestamp) / 1000).toFixed(0)} secs` : ""}</span>
            </>
          }
        >
          <ReactCodeInput ref={codeRef} type='text' value={code} fields={4} onChange={(value) => checkCode(value)} name={"code_input"} inputMode={"numeric"} className="!flex space-x-2" inputStyle={{
            maxWidth: "60px",
            height: "70px",
            textAlign: "center",
            borderRadius: "10px",
            fontSize: "30px"
          }} />
          <input type="hidden" name="code" value={code} />
        </FormItem>
      </div>
    </>
  );
};

export default CreateAccount2;
