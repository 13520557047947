import Heading from "components/Heading/Heading";
import React, { FC, useEffect, useState } from "react";
import NcImage from "shared/NcImage/NcImage";
import HIW1img from "images/HIW1.png";
import HIW2img from "images/HIW2.png";
import HIW3img from "images/HIW3.png";
import VectorImg from "images/VectorHIW.svg";
import Logo from "shared/Logo/Logo";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import Input from "shared/Input/Input";
import { useTranslation } from "react-i18next";
import { useSnackbar } from "notistack";
import houseInHandImg from "images/house_in_hand.png";

export interface SectionJoinWaitListProps {
  className?: string;
}

const SectionJoinWaitList: FC<SectionJoinWaitListProps> = ({
  className = "",
}) => {

  const { t } = useTranslation();
  const [email, setEmail] = useState<string>("");
  const [isSending, setIsSending] = useState<boolean>(false);
  const { enqueueSnackbar } = useSnackbar();

  const encode = (data: any) => {
    return Object.keys(data)
      .map(
        (key) => encodeURIComponent(key) + '=' + encodeURIComponent(data[key]),
      )
      .join('&');
  };

  const send = () => {
    // Send to netlify
    if (email) {
      setIsSending(true);
      fetch('/', {
        method: 'POST',
        headers: { 'Content-Type': 'application/x-www-form-urlencoded' },
        body: encode({ 
          'form-name': "waitlist", 
          'email': email,
        })
      }).finally(() => {
        enqueueSnackbar(t("home.waitlist.success"), { variant: "success" });
        setEmail("");
        setIsSending(false);
      });
    }
  }

  return (
    <div 
      className={`nc-SectionJoinWaitList -ml-4 -mr-4 md:-ml-24 md:-mr-24 relative bg-primary-50 bg-opacity-40 !-mb-24 lg:!-mb-28`}
      style={{ background: "linear-gradient(116.59deg, #33BCB5 50.61%, #348282 87.7%)" }}
    > 
      <div className="grid grid-cols-1 md:grid-cols-2 relative z-10">
        <div className="w-full h-full flex flex-col justify-center px-8 py-24 md:p-24 md:py-24 space-y-6 md:ml-12">
          <h2 className="text-white text-3xl md:text-4xl font-bold">{t('home.waitlist.title')} &nbsp;🎉</h2> 
          <p className="text-neutral-50">{t('home.waitlist.subtitle')}</p>
          
          <div className="relative">
            <Input
              type="email"
              placeholder={t('home.waitlist.placeholder')}
              value={email}
              onKeyDown={(e) => { 
                if (e.key === 'Enter') {
                  send();
                }
              }} 
              onChange={(e) => setEmail(e.target.value)}
              className="!rounded-full mt-4"
            />
            <ButtonSecondary onClick={() => send()} loading={isSending} className="!absolute top-1 right-2 mt-4" sizeClass="p-1.5"><i className="las la-arrow-right text-2xl"></i></ButtonSecondary>
          </div>
        </div>
        <div className="w-full h-full flex flex-col justify-end items-end">
          <img src={houseInHandImg} className="w-[100%] -mr-8 md:-mr-24 -mt-4 md:mt-12" />
        </div>
      </div>
    </div>
  );
};

export default SectionJoinWaitList;
