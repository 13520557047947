import { AuthContext } from "contexts/AuthContext";
import { useSnackbar } from "notistack";
import React, { ReactNode, useContext, useEffect, useRef, useState } from "react";
import { FC } from "react";
import { useHistory } from "react-router-dom";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import CreateAccount1 from "./CreateAccount1";
import CreateAccount2 from "./CreateAccount2";
import CreateAccount3 from "./CreateAccount3";
import CreateAccount4 from "./CreateAccount4";
import CreateAccount5 from "./CreateAccount5";

export interface PageCreateAccountProps {
}

const PageCreateAccount: FC<PageCreateAccountProps> = () => {

  const { user, isLoggedIn, token, login } = useContext(AuthContext);

  const [index, setIndex] = useState<number>(parseInt(localStorage.getItem('create-account-index') as string) || 0); 
  const maxStep = 4;

  const [form, setForm] = useState<any>(localStorage.getItem('create-account-form') ? JSON.parse(localStorage.getItem('create-account-form') as string) : user);
  const formRef = useRef<HTMLFormElement>(null);

  const { enqueueSnackbar } = useSnackbar();
  
  const [disableContinue, setDisableContinue] = useState<boolean>(true);
  const [loadingContinue, setLoadingContinue] = useState<boolean>(false);
  const [isGoBack, setIsGoBack] = useState<boolean>(true);
  const [isContinue, setIsConitnue] = useState<boolean>(true);

  const [done, setDone] = useState<boolean>(false);
  const [newUser, setNewUser] = useState<any>();
  const [newToken, setNewToken] = useState<String>();

  const history = useHistory();

  useEffect(() => {
    if (isLoggedIn && !user?.country) {
      setIsConitnue(true);
      setForm(user);
      setIndex(2);
    }
  }, []);

  const components: React.ReactElement[] = [
    <CreateAccount1 form={form} setDisableContinue={setDisableContinue} setLoadingContinue={setLoadingContinue} setIsGoBack={setIsGoBack} />,
    <CreateAccount2 form={form} setDisableContinue={setDisableContinue} setLoadingContinue={setLoadingContinue} setIsGoBack={setIsGoBack} />,
    <CreateAccount3 form={form} setDisableContinue={setDisableContinue} setLoadingContinue={setLoadingContinue} setIsGoBack={setIsGoBack} />,
    <CreateAccount4 form={form} setDisableContinue={setDisableContinue} setLoadingContinue={setLoadingContinue} setIsGoBack={setIsGoBack} />,
    <CreateAccount5 form={form} setDisableContinue={setDisableContinue} setLoadingContinue={setLoadingContinue} setIsGoBack={setIsGoBack} setIsContinue={setIsConitnue} done={done} user={newUser} token={newToken} />
  ];

  const keyPress = (e: any): void => {
    if (e.key == "Enter") nextStep();
  }

  const prevStep = () => {
    index == 0 ? history.push("/login") : setIndex(index-1);
  }

  const nextStep = () => {
    const data = new FormData(formRef.current as HTMLFormElement);
    const formObject = Object.fromEntries(data.entries());
    const newForm = {...form, ...formObject};
    localStorage.setItem('create-account-form', JSON.stringify(newForm));
    setForm(newForm);
    if (index < maxStep) {
      setIndex(index+1);
      setIsGoBack(true);
      localStorage.setItem('create-account-index', String(index+1));
      if (index+1 == maxStep) {
        fetch(!isLoggedIn ? `${process.env.REACT_APP_API_URL}/users/create_account` : `${process.env.REACT_APP_API_URL}/users/finish_creating_account`, {
          headers: { "Content-Type": "application/json" },
          method: "POST",
          credentials: "include",
          body: JSON.stringify({...newForm, token: token, userId: user?._id}),
        }).then((r) => {
          const token = r.headers.get('x-auth-token');
          r.json().then(user => {
            if (token) {
              setDone(true);
              setNewToken(token);
              setNewUser(user);
              localStorage.removeItem('create-account-form');
              localStorage.removeItem('create-account-index');
              // enqueueSnackbar("Account created successfully!", { variant: "success" });
            } else {
              enqueueSnackbar("There was an error while creating your account. Please try again later.", { variant: "error" });
            }
          });
        });
      }
    }
  }

  return (
    <div
      className={`nc-PageAddListing1 px-4 max-w-3xl mx-auto pb-16 py-5 min-h-[calc(100vh-88px)] md:h-auto`}
      data-nc-id="PageAddListing1"
    >
      <div className="space-y-11 relative h-full">
        <>
          {index < maxStep && (
            <div>
              <span className="text-4xl font-semibold">{index+1}</span>{" "}
              <span className="text-lg text-neutral-500 dark:text-neutral-400">
                / {maxStep+1}
              </span>
            </div>
          )}

          <form onKeyPress={keyPress} ref={formRef} className="listingSection__wrap md:pb-16 w-full display-block h-full border-b-0 md:border-b">
            {components[index]}
          </form>
          
          <div className="flex justify-end space-x-5 fixed left-0 bottom-0 md:relative p-5 w-full md:p-0">
            {isGoBack && (
              <ButtonSecondary className="w-full md:w-auto" onClick={() => { prevStep() }}>Go back</ButtonSecondary>
            )}
            {isContinue && (
              <ButtonPrimary disabled={disableContinue} loading={loadingContinue} className="w-full md:w-auto" onClick={() => { nextStep() }}>{index == maxStep ? "Create Account" : "Continue"}</ButtonPrimary>
            )}
          </div>
        </>
      </div>
    </div>
  );
};

export default PageCreateAccount;
