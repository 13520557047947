import { Tab } from "@headlessui/react";
import CarCard from "components/CarCard/CarCard";
import CommentListing from "components/CommentListing/CommentListing";
import ExperiencesCard from "components/ExperiencesCard/ExperiencesCard";
import StartRating from "components/StartRating/StartRating";
import StayCard from "components/StayCard/StayCard";
import {
  DEMO_CAR_LISTINGS,
  DEMO_EXPERIENCES_LISTINGS,
  DEMO_STAY_LISTINGS,
} from "data/listings";
import React, { FC, Fragment, useContext, useState } from "react";
import Avatar from "shared/Avatar/Avatar";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import SocialsList from "shared/SocialsList/SocialsList";
import { Helmet } from "react-helmet";
import { AuthContext } from "contexts/AuthContext";
import Logo from "shared/Logo/Logo";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionBecomeAnAuthor from "components/SectionBecomeAnAuthor/SectionBecomeAnAuthor";
import { DocumentDuplicateIcon } from "@heroicons/react/24/outline";
import NcModal from "shared/NcModal/NcModal";
import ButtonThird from "shared/Button/ButtonThird";
import ButtonPrimary from "shared/Button/ButtonPrimary";

export interface PirvateKeyModalProps {
  buttonClassName?: String;
}

const PirvateKeyModal: FC<PirvateKeyModalProps> = ({ buttonClassName = "" }) => {

  const { user, token } = useContext(AuthContext);
  const [ privateKey, setPrivateKey ] = useState<String>();
  const [ privateKeyModalOpen, setPrivateKeyModalOpen ] = useState<boolean>(false);
  const [ isLoadingPrivateKey, setIsLoadingPrivateKey ] = useState<boolean>(false);
  
  const exportPrivateKey = () => {
    setIsLoadingPrivateKey(true);
    fetch(`${process.env.REACT_APP_API_URL}/wallet/get_private_key`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        userId: user?.id,
        token: token
      })
    }).then((response) => {
      return response.json();
    }).then((json) => {
      setPrivateKey(json.data);
    }).finally(() => {
      setIsLoadingPrivateKey(false);
    });
  }

  const renderModal = () => {
    return (
      <>
        <div className="flex flex-col space-y-5">
          <span className="block">
            Import your Reblox Wallet private key into any other wallet provider 
            (Metamask, Coinbase, TrustWallet, etc).
          </span>
          <code className="break-all p-3 bg-primary-50 rounded-lg">
            {isLoadingPrivateKey ? <i className="las la-circle-notch la-spin text-2xl m-auto !table"></i> : privateKey}
          </code>
          <span className="text-sm text-neutral-400 dark:text-neutral-500">
            Remember, anyone with your private key can have access to your wallet funds. 
          </span>
        </div>
      </>
    );
  }
  const renderModalFooter = () => {
    return (
      <>
        <ButtonPrimary onClick={() => setPrivateKeyModalOpen(false)}>Close</ButtonPrimary>
      </>
    );
  }

  return (
    <NcModal
      renderTrigger={(openModal) => (<ButtonThird onClick={(event) => { event.stopPropagation(); exportPrivateKey(); setPrivateKeyModalOpen(true); }} sizeClass="px-0 py-1" className={`!text-sm font-semibold text-primary-800 ${buttonClassName}`}>Export private key</ButtonThird>)}
      renderContent={renderModal}
      renderFooter={renderModalFooter}
      isOpenProp={privateKeyModalOpen}
      modalTitle="Export private key"
    />
  );
};

export default PirvateKeyModal;
