import React, { FC, Fragment, useEffect, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { ArrowRightIcon, CalendarIcon } from "@heroicons/react/24/outline";
import LocationMarker from "components/AnyReactComponent/LocationMarker";
import CommentListing from "components/CommentListing/CommentListing";
import FiveStartIconForRate from "components/FiveStartIconForRate/FiveStartIconForRate";
import GuestsInput from "components/HeroSearchForm/GuestsInput";
import { DateRage } from "components/HeroSearchForm/StaySearchForm";
import StartRating from "components/StartRating/StartRating";
import GoogleMapReact from "google-map-react";
import useWindowSize from "hooks/useWindowResize";
import moment from "moment";
import {
  DayPickerRangeController,
  FocusedInputShape,
  isInclusivelyAfterDay,
} from "react-dates";
import Avatar from "shared/Avatar/Avatar";
import Badge from "shared/Badge/Badge";
import ButtonCircle from "shared/Button/ButtonCircle";
import ButtonPrimary from "shared/Button/ButtonPrimary";
import ButtonSecondary from "shared/Button/ButtonSecondary";
import ButtonClose from "shared/ButtonClose/ButtonClose";
import Input from "shared/Input/Input";
import NcImage from "shared/NcImage/NcImage";
import LikeSaveBtns from "./LikeSaveBtns";
import ModalPhotos from "./ModalPhotos";
import BackgroundSection from "components/BackgroundSection/BackgroundSection";
import SectionSliderNewCategories from "components/SectionSliderNewCategories/SectionSliderNewCategories";
import SectionSubscribe2 from "components/SectionSubscribe2/SectionSubscribe2";
import StayDatesRangeInput from "components/HeroSearchForm/StayDatesRangeInput";
import MobileFooterSticky from "./MobileFooterSticky";
import { SquareFootOutlined } from "@material-ui/icons";
import { TagIcon } from "@heroicons/react/24/solid";
import { LinearProgress } from "@mui/material";
import Slider from "rc-slider";
import { useParams } from "react-router-dom";
import convertNumbThousand from "utils/convertNumbThousand";


export interface ListingStayDetailPageProps {
  className?: string;
  isPreviewMode?: boolean;
}

const ListingStayDetailPage: FC<ListingStayDetailPageProps> = ({
  className = "",
  isPreviewMode,
}) => {

  const { slug } = useParams<{ slug: string }>();
  const [property, setProperty] = useState<any>();
  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [isOpen, setIsOpen] = useState(false);
  const [openFocusIndex, setOpenFocusIndex] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    fetch(`${process.env.REACT_APP_API_URL}/properties/get_property`, {
      headers: { "Content-Type": "application/json" },
      method: "POST",
      credentials: "include",
      body: JSON.stringify({
        slug: slug
      })
    }).then((response) => {
      return response.json();
    }).then((json) => {
      setProperty(json.data);
    }).finally(() => {
      setIsLoading(false);
    });
  }, []);

  if (isLoading) return <></>;

  // useEffect(() => {
  //   fetch(`${process.env.REACT_APP_API_URL}/properties/create_property`, {
  //     headers: { "Content-Type": "application/json" },
  //     method: "POST",
  //     credentials: "include",
  //     body: JSON.stringify({
  //       token_address: "0x123456789012345678901234567890123",
  //       images: ["https://photos.zillowstatic.com/fp/f147bfed3a2b0ae330ec1c84d2d0a0c2-uncropped_scaled_within_1536_1152.webp","https://photos.zillowstatic.com/fp/c243f316a3dd70d6808bf20337ca2732-uncropped_scaled_within_1536_1152.webp","https://photos.zillowstatic.com/fp/b11d0083841818c071ee66c32df0752b-uncropped_scaled_within_1536_1152.webp","https://photos.zillowstatic.com/fp/9bd890fabc2d1c45a2dd0a76f8205719-uncropped_scaled_within_1536_1152.webp","https://images.pexels.com/photos/271667/pexels-photo-271667.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"],
  //       address: "3419 5th St SW",
  //       city: "Lehigh Acres",
  //       state: "FL",
  //       country: "USA",
  //       description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat ducimus reprehenderit voluptate nam illo velit, maiores totam omnis voluptatum. Iste corrupti placeat temporibus vitae at ipsam error a libero odio?",
  //       investment_term_months: 8,
  //       unit_type: "Single Family",
  //       unit_quantity: 1,
  //       unit_sqft: 2418,
  //       bedroom_quantity: 3,
  //       bath_quantity: 2.5,
  //       city_description: "Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat ducimus reprehenderit voluptate nam illo velit, maiores totam omnis voluptatum. Iste corrupti placeat temporibus vitae at ipsam error a libero odio? Lorem ipsum dolor sit amet, consectetur adipisicing elit. Placeat ducimus reprehenderit voluptate nam illo velit, maiores totam omnis voluptatum. Iste corrupti placeat temporibus vitae at ipsam error a libero odio?",
  //       cost_details: [{
  //         concept: "lorem",
  //         value: "ipsum"
  //       }],
  //       investment_details: [{
  //         concept: "lorem",
  //         value: "ipsum"
  //       }],
  //       documents: [{
  //         name: "Appraisal.pdf",
  //         url: "https://www.orimi.com/pdf-test.pdf"
  //       }],
  //       irr: 12.54,
  //       featured: true,
  //     }),
  //   }).then((response) => {
  //     return response.json();
  //   })
  // }, []);

  const goToCheckout = () => {
    localStorage.setItem('checkout-property', JSON.stringify(property));
    window.location.href="/checkout";
    return;
  }

  const handleOpenModal = (index: number) => {
    setIsOpen(true);
    setOpenFocusIndex(index);
  };

  const handleCloseModal = () => setIsOpen(false);

  const projectHeader = () => {
    return (
      <div className="listingSection__wrap !space-y-6">
        {/* 1 */}
        <div className="flex justify-between items-center">
          <Badge name="Single Family" />
          <LikeSaveBtns />
        </div>

        {/* 2 */}
        <h2 className="text-2xl sm:text-3xl lg:text-4xl font-semibold">
          {property?.address}, {property?.city}
        </h2>

        {/* 3 */}
        <div className="flex items-center space-x-4">
          {/* <StartRating /> */}
          {/* <span>·</span> */}
          <span>
            <i className="las la-map-marker-alt"></i>
            <span className="ml-1"> {property?.state}, {property?.country}</span>
          </span>
        </div>

        {/* 4 */}
        <div className="flex items-center">
          {/* <Avatar hasChecked sizeClass="h-10 w-10" radius="rounded-full" /> */}
          <CalendarIcon className="h-4 w-4" />
          <span className="ml-2.5 text-neutral-500 dark:text-neutral-400">
            Investment term:{" "}
            <span className="text-neutral-900 dark:text-neutral-200 font-bold">
              {property?.investment_term_months} months
            </span>
          </span>
        </div>

        {/* 5 */}
        <div className="w-full border-b border-neutral-100 dark:border-neutral-700" />

        {/* 6 */}
        <div className="flex items-center justify-between xl:justify-start space-x-8 xl:space-x-12 text-sm text-neutral-700 dark:text-neutral-300">
          <div className="flex items-center space-x-3 ">
            <i className="las la-th text-2xl"></i>
            <span className="">
              {property?.unit_sqft} <span className="hidden sm:inline-block">sqft</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bed text-2xl"></i>
            <span className=" ">
              {property?.bedroom_quantity} <span className="hidden sm:inline-block">bedrooms</span>
            </span>
          </div>
          <div className="flex items-center space-x-3">
            <i className=" las la-bath text-2xl"></i>
            <span className=" ">
              {property?.bath_quantity} <span className="hidden sm:inline-block">baths</span>
            </span>
          </div>
        </div>
      </div>
    );
  };

  const projectInformation = () => {
    return (
      <div className="listingSection__wrap">
        <h2 className="text-2xl font-semibold">Project information</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="text-neutral-6000 dark:text-neutral-300">
          {property?.description}
        </div>
      </div>
    );
  };
  
  const documents = () => {
    return (
      <div className="listingSection__wrap">
        <div>
          <h2 className="text-2xl font-semibold">Important documents</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Lorem ipsum dolor sit amet consectetur adipisicing elit. Ipsam minima mollitia doloribus, libero quisquam autem deleniti.
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        <div className="overflow-x-auto pb-5" style={{ whiteSpace: "nowrap" }}>
          {property?.documents.map((item:any) => (
            <a href={item.url} target="_blank">
              <div className="inline-block m-1 cursor-pointer transition-all">
                <div className="flex flex-col space-y-3 justify-center items-center p-3 border rounded-xl w-36 overflow-hidden hover:border-primary-500 hover:border-2 transition-all">
                  <i className="las la-file-pdf text-5xl"></i>
                  <p className="text-xs max-w-full white-nowrap text-ellipsis overflow-hidden" style={{ whiteSpace: "nowrap" }}>{item.name}</p>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    );
  };

  const costDetails = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Cost details </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {property?.cost_details.map((item:any, index:number) => (
              <div className={`p-4 flex justify-between items-center space-x-4 rounded-lg ${index%2 == 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""}`}>
                <span>{item.concept}</span>
                <span>{item.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const investmentDetails = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Investment details </h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            Prices may increase on weekends or holidays
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
        {/* CONTENT */}
        <div className="flow-root">
          <div className="text-sm sm:text-base text-neutral-6000 dark:text-neutral-300 -mb-4">
            {property?.investment_details.map((item:any, index:number) => (
              <div className={`p-4 flex justify-between items-center space-x-4 rounded-lg ${index%2 == 0 ? "bg-neutral-100 dark:bg-neutral-800" : ""}`}>
                <span>{item.concept}</span>
                <span>{item.value}</span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  // const renderSectionCheckIndate = () => {
  //   return (
  //     <div className="listingSection__wrap overflow-hidden">
  //       {/* HEADING */}
  //       <div>
  //         <h2 className="text-2xl font-semibold">Availability</h2>
  //         <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
  //           Prices may increase on weekends or holidays
  //         </span>
  //       </div>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       {/* CONTENT */}

  //       <div className="listingSection__wrap__DayPickerRangeController flow-root">
  //         <div className="-mx-4 sm:mx-auto xl:mx-[-22px]">
  //           <DayPickerRangeController
  //             startDate={selectedDate.startDate}
  //             endDate={selectedDate.endDate}
  //             onDatesChange={(date) => setSelectedDate(date)}
  //             focusedInput={focusedInputSectionCheckDate}
  //             onFocusChange={(focusedInput) =>
  //               setFocusedInputSectionCheckDate(focusedInput || "startDate")
  //             }
  //             initialVisibleMonth={null}
  //             numberOfMonths={windowSize.width < 1280 ? 1 : 2}
  //             daySize={getDaySize()}
  //             hideKeyboardShortcutsPanel={false}
  //             isOutsideRange={(day) => !isInclusivelyAfterDay(day, moment())}
  //           />
  //         </div>
  //       </div>
  //     </div>
  //   );
  // };

  // const renderSection5 = () => {
  //   return (
  //     <div className="listingSection__wrap">
  //       {/* HEADING */}
  //       <h2 className="text-2xl font-semibold">Partner developer</h2>
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>

  //       {/* host */}
  //       <div className="flex items-center space-x-4">
  //         <Avatar
  //           hasChecked
  //           hasCheckedClass="w-4 h-4 -top-0.5 right-0.5"
  //           sizeClass="h-14 w-14"
  //           radius="rounded-full"
  //         />
  //         <div>
  //           <a className="block text-xl font-medium" href="##">
  //             Kevin Francis
  //           </a>
  //           <div className="mt-1.5 flex items-center text-sm text-neutral-500 dark:text-neutral-400">
  //             <StartRating />
  //             <span className="mx-2">·</span>
  //             <span> 12 places</span>
  //           </div>
  //         </div>
  //       </div>

  //       {/* desc */}
  //       <span className="block text-neutral-6000 dark:text-neutral-300">
  //         Providing lake views, The Symphony 9 Tam Coc in Ninh Binh provides
  //         accommodation, an outdoor swimming pool, a bar, a shared lounge, a
  //         garden and barbecue facilities...
  //       </span>

  //       {/* info */}
  //       <div className="block text-neutral-500 dark:text-neutral-400 space-y-2.5">
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M8 7V3m8 4V3m-9 8h10M5 21h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v12a2 2 0 002 2z"
  //             />
  //           </svg>
  //           <span>Joined in March 2016</span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M7 8h10M7 12h4m1 8l-4-4H5a2 2 0 01-2-2V6a2 2 0 012-2h14a2 2 0 012 2v8a2 2 0 01-2 2h-3l-4 4z"
  //             />
  //           </svg>
  //           <span>Response rate - 100%</span>
  //         </div>
  //         <div className="flex items-center space-x-3">
  //           <svg
  //             xmlns="http://www.w3.org/2000/svg"
  //             className="h-6 w-6"
  //             fill="none"
  //             viewBox="0 0 24 24"
  //             stroke="currentColor"
  //           >
  //             <path
  //               strokeLinecap="round"
  //               strokeLinejoin="round"
  //               strokeWidth={1.5}
  //               d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"
  //             />
  //           </svg>

  //           <span>Fast response - within a few hours</span>
  //         </div>
  //       </div>

  //       {/* == */}
  //       <div className="w-14 border-b border-neutral-200 dark:border-neutral-700"></div>
  //       <div>
  //         <ButtonSecondary href="##">See host profile</ButtonSecondary>
  //       </div>
  //     </div>
  //   );
  // };

  const location = () => {

    const initStreetView = ({map, maps}: {map:any, maps:any}) => {
      var sv = new maps.StreetViewService();
      var panorama = new maps.StreetViewPanorama(document.getElementById("map"));
      sv.getPanorama({location: {lat: 37.869, lng: -122.2547}, radius: 50}, processSVData);
      function processSVData(data:any, status:any) {
          var marker = new maps.Marker({
              position: data.location.latLng,
              map: map,
              title: data.location.description
          });
          panorama.setPano(data.location.pano)
          panorama.setPov({
              heading: 270,
              pitch: 0
          });
          panorama.setVisible(true);
      }
    }

    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <div>
          <h2 className="text-2xl font-semibold">Location</h2>
          <span className="block mt-2 text-neutral-500 dark:text-neutral-400">
            {property?.address}, {property?.city}, {property?.state}, {property?.country}
          </span>
        </div>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* MAP */}
        <div className="aspect-w-5 aspect-h-5 sm:aspect-h-3">
          <div className="rounded-xl overflow-hidden">
            <GoogleMapReact
              bootstrapURLKeys={{
                key: process.env.REACT_APP_GOOGLE_API_KEY as string,
              }}
              onGoogleApiLoaded={initStreetView}
              yesIWantToUseGoogleMapApiInternals
              defaultZoom={15}
              defaultCenter={{
                lat: 55.9607277,
                lng: 36.2172614,
              }}
            >
              <LocationMarker lat={55.9607277} lng={36.2172614} />
            </GoogleMapReact>
          </div>
        </div>
      </div>
    );
  };

  const cityDescription = () => {
    return (
      <div className="listingSection__wrap">
        {/* HEADING */}
        <h2 className="text-2xl font-semibold">Why {property?.city}?</h2>
        <div className="w-14 border-b border-neutral-200 dark:border-neutral-700" />

        {/* CONTENT */}
        <div>
          <span className="block text-neutral-500 dark:text-neutral-400">
            {property?.city_description}
          </span>
        </div>
      </div>
    );
  };
  
  const timeline = () => {
    if (property?.timeline?.length == 0) return <></>;
    const weekday = ["Sunday","Monday","Tuesday","Wednesday","Thursday","Friday","Saturday"];
    const months = ["January","February","March","April","May","June","July","August","September","October","November", "December"];
    return (
      <div className="listingSection__wrap">
        <span className="text-2xl font-semibold block">
          Project timeline
        </span>
        <div className="mt-8 flex">
          <div className="flex-shrink-0 flex flex-col items-center py-2">
            {property?.timeline.map((item:any, index:number) => (
              <>
                <span className={`block w-6 h-6 rounded-full border ${item?.done == true ? "border-primary-6000 bg-primary-6000" : "border-neutral-400"}`}></span>
                {index+1 < property?.timeline?.length && <span className={`block flex-grow border-l ${item?.done == true ? "border-primary-6000" : "border-neutral-400"} border-solid my-1`}></span>}
              </>
            ))}
            {/* <span className="block w-6 h-6 rounded-full border border-neutral-400"></span>
            <span className="block flex-grow border-l border-neutral-400 border-dashed my-1"></span>
            <span className="block w-6 h-6 rounded-full border border-neutral-400"></span> */}
          </div>
          <div className="ml-4 space-y-14 text-sm">
            {property?.timeline.map((item:any, index:number) => (
              <div className="flex flex-col space-y-1">
                <span className="text-neutral-500 dark:text-neutral-400">
                  {weekday[new Date(item.date).getDay()]}, {months[new Date(item.date).getMonth()]} {new Date(item.date).getDate()}, {new Date(item.date).getFullYear()}
                </span>
                <span className="text-lg font-semibold">
                  {item.title}
                </span>
                <span className="text-sm text-neutral-500 dark:text-neutral-400">
                  {item.description}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderSidebar = () => {
    return (
      <div className="listingSectionSidebar__wrap shadow-xl overflow-hidden p-5 lg:pb-inherit">

        {/* PRICE */}
        <div className="flex justify-between items-end bg-primary-500 text-white p-8 py-6 -ml-8 -mr-8 -mt-5 lg:-mt-5">
          <span className="text-3xl lg:text-4xl font-semibold">
            {property?.irr}%
            <span className="ml-1 text-xl lg:text-2xl font-normal text-neutral-100">
              IRR
            </span>
          </span>
          <div className="text-xl lg:text-2xl font-normal text-neutral-100">
            {parseFloat(String(property?.irr * (12 / property?.investment_term_months))).toFixed(2)}% APR
          </div>
        </div>

        {/* PRICE */}
        <div className="flex justify-between items-end">
          <span className="text-2xl lg:text-3xl font-semibold text-neutral-800 dark:text-neutral-700">
            ${convertNumbThousand(102.23)}
            {/* <span className="ml-1 text-base font-normal text-neutral-500 dark:text-neutral-400">
              /token
            </span> */}
          </span>
          <div className="text-sm lg:text-base font-normal text-neutral-500 dark:text-neutral-400">
            Token price
          </div>
        </div>

        {/* SUM */}
        <div className="flex flex-col space-y-4 text-sm lg:text-base">
          <div className="flex justify-between text-neutral-500 dark:text-neutral-400">
            <span>Amount to raise</span>
            <span>${convertNumbThousand(property?.toRaise)}</span>
          </div>
          <div className="flex justify-between text-neutral-500 dark:text-neutral-400">
            <span>Amount raised</span>
            <span>$block,chain</span>
          </div>
          <div className="flex justify-between text-neutral-500 dark:text-neutral-400">
            <span>Amount left</span>
            <span>$block,chain</span>
          </div>
          {/* <div className="border-b border-neutral-200 dark:border-neutral-700"></div> */}
          <br />
          <LinearProgress sx={{ height: "0.75rem", borderRadius: "1rem" }} value={10} variant="determinate" />
          <div className="flex justify-between text-neutral-500 dark:text-neutral-400 !mt-1 text-sm">
            <span>$block,chain / $block,chain</span>
            <span>10%</span>
          </div>
        </div>

        {/* <Slider min={0} step={1000} max={20000} dots={true} dotStyle={{borderColor: "#818cf8", width: "1px"}} defaultValue={10000}  /> */}

        {/* SUBMIT */}
        <ButtonPrimary onClick={() => goToCheckout()}>Invest</ButtonPrimary>
      </div>
    );
  };

  return (
    <div
      className={`ListingDetailPage nc-ListingStayDetailPage ${className}`}
      data-nc-id="ListingStayDetailPage"
    >
      {/* SINGLE HEADER */}
      <>
        <header className="container 2xl:px-14 pt-6 rounded-md sm:rounded-xl">
          <div className="relative grid grid-cols-3 sm:grid-cols-4 gap-1 sm:gap-2">
            <div
              className="col-span-2 row-span-3 sm:row-span-2 relative rounded-md sm:rounded-xl overflow-hidden cursor-pointer"
              onClick={() => handleOpenModal(0)}
            >
              <NcImage
                containerClassName="absolute inset-0"
                className="object-cover w-full h-full rounded-md sm:rounded-xl"
                src={property?.images[0]}
              />
              <div className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity"></div>
            </div>
            {property?.images.filter((_: any, i: number) => i >= 1 && i < 5).map((item: any, index: any) => (
              <div
                key={index}
                className={`relative rounded-md sm:rounded-xl overflow-hidden ${
                  index >= 3 ? "hidden sm:block" : ""
                }`}
              >
                <NcImage
                  containerClassName="aspect-w-4 aspect-h-3 sm:aspect-w-6 sm:aspect-h-5"
                  className="object-cover w-full h-full rounded-md sm:rounded-xl "
                  src={item || ""}
                />

                {/* OVERLAY */}
                <div
                  className="absolute inset-0 bg-neutral-900 bg-opacity-20 opacity-0 hover:opacity-100 transition-opacity cursor-pointer"
                  onClick={() => handleOpenModal(index + 1)}
                />
              </div>
            ))}

            <div
              className="absolute hidden md:flex md:items-center md:justify-center left-3 bottom-3 px-4 py-2 rounded-xl bg-neutral-100 text-neutral-500 cursor-pointer hover:bg-neutral-200 z-10"
              onClick={() => handleOpenModal(0)}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-5 w-5"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={1.5}
                  d="M4 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2V6zM14 6a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2V6zM4 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2H6a2 2 0 01-2-2v-2zM14 16a2 2 0 012-2h2a2 2 0 012 2v2a2 2 0 01-2 2h-2a2 2 0 01-2-2v-2z"
                />
              </svg>
              <span className="ml-2 text-neutral-800 text-sm font-medium">
                Show all photos
              </span>
            </div>
          </div>
        </header>
        {/* MODAL PHOTOS */}
        <ModalPhotos
          imgs={property?.images}
          isOpen={isOpen}
          onClose={handleCloseModal}
          initFocus={openFocusIndex}
          uniqueClassName="nc-ListingStayDetailPage-modalPhotos"
        />
      </>

      {/* MAIn */}
      <main className="container relative z-10 mt-11 flex flex-col lg:flex-row ">
        {/* CONTENT */}
        <div className="w-full lg:w-3/5 xl:w-2/3 space-y-8 lg:space-y-10 lg:pr-10">
          {projectHeader()}
            <div className="block lg:hidden">{renderSidebar()}</div>
          {timeline()}
          {projectInformation()}
          {costDetails()}
          {investmentDetails()}
          {documents()}
          {location()}
          {cityDescription()}
        </div>

        {/* SIDEBAR */}
        <div className="hidden lg:block flex-grow mt-14 lg:mt-0">
          <div className="sticky top-28">{renderSidebar()}</div>
        </div>
      </main>

      {/* STICKY FOOTER MOBILE */}
      {!isPreviewMode && <MobileFooterSticky />}

      {/* OTHER SECTION */}
      {!isPreviewMode && (
        <div className="container py-24 lg:py-32">
          {/* SECTION 1 */}
          <div className="relative py-16">
            <BackgroundSection />
            <SectionSliderNewCategories
              heading="Explore by types of stays"
              subHeading="Explore houses based on 10 types of stays"
              categoryCardType="card5"
              itemPerRow={5}
              sliderStyle="style2"
              uniqueClassName={"ListingStayDetailPage1"}
            />
          </div>

          {/* SECTION */}
          <SectionSubscribe2 className="pt-24 lg:pt-32" />
        </div>
      )}
    </div>
  );
};

export default ListingStayDetailPage;
