import { CheckCircleIcon } from "@heroicons/react/24/outline";
import { CheckIcon } from "@heroicons/react/24/solid";
import { AuthContext } from "contexts/AuthContext";
import React, { FC, useContext, useEffect, useState } from "react";
import { ColorRing } from "react-loader-spinner";

export interface CreateAccount5Props {
  form: any;
  setDisableContinue(disabled: boolean): void;
  setLoadingContinue(disabled: boolean): void;
  setIsGoBack(disabled: boolean): void;
  setIsContinue(disabled: boolean): void;
  done: boolean;
  user?: any;
  token?: String;
}

const CreateAccount5: FC<CreateAccount5Props> = ({form, setDisableContinue, setLoadingContinue, setIsGoBack, setIsContinue, done, token, user}) => {

  const { login } = useContext(AuthContext);
  const [check, setCheck] = useState<boolean>(false);

  useEffect(() => {
    setIsGoBack(false);
    setIsContinue(false);
  }, []);

  useEffect(() => {
    if (done == true) {
      setTimeout(() => {
        setCheck(true);
      }, 2000);
      setTimeout(() => {
        login(token as string, user);
        window.location.href="/account";
      }, 7000);
    } 
  }, [done]);

  return (
    <>
      <div className="space-y-8 flex items-center justify-center h-full">
        <div className="flex flex-col justify-center items-center space-y-3 text-center">
          {!check && (
            <>
              <ColorRing
                visible={true}
                height="150"
                width="150"
                ariaLabel="blocks-loading"
                wrapperStyle={{}}
                wrapperClass="blocks-wrapper"
                colors={['#e15b64', '#f47e60', '#f8b26a', '#abbd81', '#849b87']}
              />
              <p className="text-lg">Creating account, please wait...</p>
            </>
          )}
          {check && (
            <>
              <CheckCircleIcon className="h-36 w-36 text-green-500" />
              <br/>
              <p className="text-lg">Account created successfully.</p>
              <br/>
              <p className="text-lg">Redirecting...</p>
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default CreateAccount5;
function setIsContinue(arg0: boolean) {
  throw new Error("Function not implemented.");
}

